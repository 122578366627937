<template>
  <div>
    <b-row>
      <b-col
        class="pt-2"
        md="6"
      >
        <b-form-group
          class="px-2 px-lg-5"
          :label="$t('name')"
          label-for="nameInput"
        >
          <b-form-input
            placeholder="Name of event"
            v-model="eventInput.name"
            id="nameInput"
          />
        </b-form-group>

      </b-col>
      <b-col
        class="pt-2"
        md="6"
      >
        <b-form-group
          class="px-2 px-lg-5"
          :label="$t('company')"
          
          label-for="companyInput"
        >
          <v-select
            :components="{OpenIndicator}"
            class="bg-white "
            :clearable="false"
            v-model="eventInput.company"
            label="name"
            :options="companies"
            @search="getCompanies"
            placeholder="Type to search"
          />

        </b-form-group>

      </b-col>
      <b-col
        class="pt-2"
        md="6"
      >
        <b-form-group
          class="px-2 px-lg-5"
          :label="$t('description')"
          label-for="DescriptionInput"
        >
          <b-form-textarea
            v-model="eventInput.description"
            id="DescriptionInput"
            :placeholder="$t('Write a description')"
            rows="5"
          />
        </b-form-group>

      </b-col>
      <b-col
        class="pt-2"
        md="6"
      >

        <b-form-group
          class="mx-2 mx-lg-5"
           :label="$t('start date')"
         
        >
          <b-form-datepicker
            calendar-width="100%"
            menu-class="w-100"
            v-model="eventInput.start_date"
            :max="startDateMax"
            :locale="$i18n.locale"
          />
          <!-- <flat-pickr
            v-model="eventInput.start_date"
            class="form-control bg-white"
          /> -->
        </b-form-group>
        <b-form-group
          class="mt-2 mx-2 mx-lg-5"
         :label="$t('end date')"
        >
          <b-form-datepicker
            menu-class="w-100"
            calendar-width="100%"
            v-model="eventInput.end_date"
            :min="endDateMin"
            :locale="$i18n.locale"
          />
          <!-- <flat-pickr
            v-model="eventInput.end_date"
            class="form-control bg-white"
          /> -->
        </b-form-group>
      </b-col>
      <b-col
        class="pt-2"
        md="6"
      >
        <b-form-group
          class="px-2 px-lg-5"
           :label="$t('location')"
         
          label-for="locationInput"
        >
          <b-form-input
            placeholder="Where the event is going to be"
            v-model="eventInput.location"
            id="locationInput"
          />
        </b-form-group>

      </b-col>

    </b-row>
    <b-row>
      <b-col
        class=" text-right"
        cols="12"
      >
        <b-button
        class="text-capitalize"
          to="/"
          variant="outline-primary"
        >
          {{$t("cancel")}}
        </b-button>
        <b-button
          @click="submitForm()"
          class="mx-1 px-3"
          variant="primary"
        >
          <div
            v-if="submitLoading"
            class="px-1"
          >
            <b-spinner
              small
              style="width: 1.3rem; height: 1.3rem;"
            />
            <span class="sr-only">Loading...</span>
          </div>
          <span class="text-capitalize" v-else>
            
             {{$t("save & next")}}
          </span>

        </b-button>
      </b-col>
    </b-row>
  </div>

</template>

<script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BSpinner,
    BFormDatepicker,
  } from "bootstrap-vue";

  import flatPickr from "vue-flatpickr-component";
  import vSelect from "vue-select";

  export default {
    props: ["event", "step"],
    components: {
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BButton,
      BFormTextarea,
      flatPickr,
      vSelect,
      BSpinner,
      BFormDatepicker,
    },
    data() {
      return {
        OpenIndicator: {
          render: (createElement) =>
            createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
        },

        companies: [],
        cancelToken: undefined,
        submitLoading: false,
        eventInput: {
          name: "",
          start_date: null,
          end_date: null,
          company: null,
          description: "",
          location: "",
          step: "",
        },
        editMode: false,
      };
    },
    computed: {
      startDateMax() {
        let endDate = null;
        if (this.eventInput.end_date) {
          const timestamp = Date.parse(this.eventInput.end_date);
          endDate = new Date(timestamp);
        }
        return endDate;
      },
      endDateMin() {
        let startDate = null;
        if (this.eventInput.start_date) {
          const timestamp = Date.parse(this.eventInput.start_date);
          startDate = new Date(timestamp);
        }
        return startDate;
      },
    },
    // watch: {
    //   start_date(val) {
    //     console.log(val);
    //   },
    // },
    mounted() {
      if (this.event) {
        this.eventInput = { ...this.event };
        this.eventInput.company_id = this.event.company
          ? this.event.company.id
          : null;
        this.editMode = true;
      }
      this.eventInput.step = this.step;
      this.getCompanies("", false);
    },
    methods: {
      getCompanies(search, loading) {
        if (typeof this.cancelToken != typeof undefined) {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.cancelToken = this.$http.CancelToken.source();

        let data = {
          search: search,
          per_page: 10,
          page: 1,
        };

        const requestData = {
          method: "get",
          url: "companies",
          params: data,
          cancelToken: this.cancelToken.token,
        };
        this.$http(requestData)
          .then((response) => {
            let data = response.data.data;
            this.companies = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      submitForm() {
        if (this.submitLoading) return;
        let errorMessage = null;
        if (!errorMessage && !this.eventInput.name) {
          errorMessage = this.$t("Please enter event name");
        }
        if (!errorMessage && !this.eventInput.description) {
          errorMessage = this.$t("Please enter event description");
        }
        if (!errorMessage && !this.eventInput.location) {
          errorMessage = this.$t("Please enter event location");
        }
        if (!errorMessage && !this.eventInput.company) {
          errorMessage = this.$t("Please select event company");
        }
        if (!errorMessage && !this.eventInput.start_date) {
          errorMessage = this.$t("Please select event start date");
        }
        if (!errorMessage && !this.eventInput.end_date) {
          errorMessage = this.$t("Please select event end date");
        }

        if (errorMessage) {
          this.$emit("showToast", { variant: "danger", message: errorMessage });

          return;
        }

        this.submitLoading = true;
        this.eventInput.company_id = this.eventInput.company.id;
        if (this.eventInput.primary_color && this.eventInput.primary_color.hex)
          this.eventInput.primary_color = this.eventInput.primary_color.hex;
        if (
          this.eventInput.secondary_color &&
          this.eventInput.secondary_color.hex
        )
          this.eventInput.secondary_color = this.eventInput.secondary_color.hex;

        if (this.eventInput.tagsInput && Array.isArray(this.eventInput.tagsInput))
          this.eventInput.tagsInput = this.eventInput.tagsInput.toString();

        const requestData = {
          method: "post",
          url: this.editMode ? `events/${this.eventInput.id}/update` : "events",
          data: this.eventInput,
        };

        this.$http(requestData)
          .then((response) => {
            let message = this.$t("Event added successfully.");
            if (this.editMode) message = this.$t(`Event updated successfully.`);
            this.$emit("showToast", { variant: "success", message });
            this.submitLoading = false;
            this.editMode = true;
            this.eventInput.id = response.data.data.id;
            this.$emit("setEvent", { ...this.eventInput });
            this.$emit("addPassedStep", this.step);
            this.$emit("goNext");
          })
          .catch((err) => {
            let message = this.$t("Unable to add event.");
            if (this.editMode) message = this.$t(`Unable to update event.`);
            if (err.response && err.response.data && err.response.data.message)
              message = err.response.data.message;
            this.$emit("showToast", { variant: "danger", message });

            this.submitLoading = false;
            console.log(err);
          });
      },
    },
  };
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
      </b-col>
    </b-row>
    <div v-if="agents">
      <div class="d-flex justify-content-between align-items-center">
        <per-page v-model="perPage" class="w-50" />
        <search class="w-50" v-model="searchInput" />
      </div>

      <b-card body-class="p-0" class="mt-1">
        <table-head :items="tableHeadings" @sort="sort" />
        <b-row
          class="py-1 mx-1 border-bottom"
          v-for="agent in agents"
          :key="agent.id"
        >
          <b-col class="d-flex align-items-center pl-0">
            <div class="mr-1">
              <b-avatar :src="agent.user.avatar" size="40"></b-avatar>
            </div>
            <div class="d-flex flex-column">
              <b>
                {{ agent.user.first_name + " " + agent.user.last_name }}
              </b>
              <span class="fontSize0-9Rem">
                {{ agent.user.email }}
              </span>
            </div>
          </b-col>
          <b-col class="d-flex align-items-center">
            {{ agent.identifier }}
          </b-col>
          <b-col class="d-flex align-items-center">
            {{ agent.company ? agent.company.name : "-" }}
          </b-col>
          <b-col class="d-flex align-items-center">
            <b-button
              @click="showConfirmModal(agent)"
              variant="flat-danger"
              class="font-weight-bolder"
            >
              <feather-icon icon="Trash2Icon" size="20" />
            </b-button>
            <b-button
              @click="showFormModal(agent)"
              variant="flat-secondary"
              class="font-weight-bolder"
            >
              <feather-icon icon="Edit2Icon" size="20" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <pagination v-model="page" :paginationData="paginationData" />
    </div>
    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? $t("edit agent") : $t("add agent") }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-3">
          <label for="agent_identifier" class="text-capitalize"
            >{{ $t("identifier") }}:</label
          >
          <b-form-input
            id="agent_identifier"
            v-model="agentInput.identifier"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-3">
          <label for="agent_first_name" class="text-capitalize"
            >{{ $t("first name") }}:</label
          >
          <b-form-input
            id="agent_first_name"
            v-model="agentInput.first_name"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-3">
          <label for="agent_last_name" class="text-capitalize"
            >{{ $t("last name") }}:</label
          >
          <b-form-input
            id="agent_last_name"
            v-model="agentInput.last_name"
            type="text"
          />
        </b-form-group>

        <b-form-group class="mt-3">
          <label for="agent_location" class="text-capitalize"
            >{{ $t("email") }}:</label
          >
          <b-form-input
            id="agent_location"
            v-model="agentInput.email"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-3">
          <label for="agent_company" class="text-capitalize"
            >{{ $t("company") }}:</label
          >
          <v-select
            id="agent_company"
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="agentInput.company_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :reduce="(company) => company.id"
            :options="companies"
            @search="getCompanies"
            placeholder="Type to search"
          />
        </b-form-group>
        <b-form-group class="mt-3">
          <label for="agent_language" class="text-capitalize"
            >{{ $t("language") }}:</label
          >
          <v-select
            id="agent_language"
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="agentInput.language"
            label="title"
            :reduce="(lang) => lang.code"
            :options="languages"
            placeholder="Select language"
          />
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      perPage: 10,
      page: 1,
      agents: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      tableHeadings: [
        {
          title: "name",
          slug: "name",
          sortable: true,
        },
        {
          title: "identifier",
          slug: "identifier",
          sortable: true,
        },
        {
          title: "company name",
          slug: "company_name",
          sortable: true,
        },
        {
          title: "actions",
          sortable: false,
        },
      ],

      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      companies: [],
      companiesCancelToken: undefined,
      agentInput: {
        id: "",
        first_name: "",
        identifier: "",
        last_name: "",
        email: "",
        company_id: null,
        language: "en",
      },
      editMode: false,
      formModal: false,
      submitLoading: false,
      languages: [
        {
          title: "English",
          code: "en",
        },
        {
          title: "Spanish",
          code: "es",
        },
      ],
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getAgents();
    this.getCompanies();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getAgents();
    },
    perPage(value) {
      this.page = 1;
      this.getAgents();
    },
    page(value) {
      this.getAgents();
    },
  },
  methods: {
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getAgents();
    },

    async getAgents() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        search: this.searchInput,
        per_page: this.perPage,
        page: this.page,
        sort_by: this.sortBy,
        sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "agents",
        params: data,
        cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data.data;
          this.agents = data.data;
          this.paginationData = {
            current_page: data.meta.current_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
            per_page: data.meta.per_page,
            last_page: data.meta.last_page,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showConfirmModal(agent) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("Please confirm that you want to delete agent") +
            `: ${agent.user.first_name} ${agent.user.last_name}.`,
          {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (confirmed) => {
          if (confirmed) {
            let agentIndex = 0;
            this.agents = await this.agents.filter((agentItem, index) => {
              if (agentItem.id == agent.id) {
                agentIndex = index;
              }
              return agentItem.id !== agent.id;
            });

            const requestData = {
              method: "delete",
              url: `agents/${agent.id}`,
            };
            this.$http(requestData)
              .then((response) => {
                this.$bvToast.toast(
                  `Agent: ${agent.user.first_name} ${agent.user.last_name} deleted successfully.`,
                  {
                    title: this.$t("Done"),
                    variant: "success",
                    solid: true,
                  }
                );
              })
              .catch((err) => {
                let message = this.$t("Unable to delete!");
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$bvToast.toast(message, {
                  title: this.$t("Error"),
                  variant: "danger",
                  solid: true,
                });
                this.agents.splice(agentIndex, 0, agent);
              });
          }
        });
    },
    showFormModal(agent) {
      if (agent) {
        this.agentInput = {
          id: agent.id,
          first_name: agent.user.first_name,
          identifier: agent.identifier,
          last_name: agent.user.last_name,
          email: agent.user.email,
          company_id: agent.company ? agent.company.id : null,
          language: agent.language || "en",
        };
        this.editMode = true;
      } else {
        this.agentInput = {
          id: "",
          first_name: "",
          identifier: "",
          last_name: "",
          email: "",
          company_id: null,
          language: "en",
        };
        this.editMode = false;
      }
      this.formModal = true;
    },
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.agentInput.identifier) {
        errorMessage = this.$t("Please enter agent identifier");
      }
      if (!errorMessage && !this.agentInput.first_name) {
        errorMessage = this.$t("Please enter agent first name");
      }
      if (!errorMessage && this.agentInput.first_name.length < 2) {
        errorMessage = this.$t(
          "Agent first name should be at least 2 characters"
        );
      }
      if (!errorMessage && !this.agentInput.last_name) {
        errorMessage = this.$t("Please enter agent last name");
      }

      if (!errorMessage && this.agentInput.last_name.length < 2) {
        errorMessage = this.$t(
          "Agent last name should be at least 2 characters"
        );
      }
      if (!errorMessage && !this.agentInput.email) {
        errorMessage = this.$t("Please enter agent email");
      }
      if (!errorMessage && !this.validateEmail()) {
        errorMessage = this.$t("Please enter a valid email address");
      }
      if (!errorMessage && !this.agentInput.company_id) {
        errorMessage = this.$t("Please select company");
      }

      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.submitLoading = true;

      const requestData = {
        method: this.editMode ? "patch" : "post",
        url: this.editMode ? `agents/${this.agentInput.id}` : `agents`,
        data: this.agentInput,
      };

      this.$http(requestData)
        .then((response) => {
          let message = this.$t("Agent added successfully");
          if (this.editMode) message = this.$t("Agent updated successfully");
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          if (!this.editMode && this.agents && this.agents.length > 0) {
            this.$refs.agentsPaginationComponent.clear();
            this.$refs.agentsSearchComponent.clear();
            this.$refs.agentsTableHeadComponent.clear();
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }

          this.submitLoading = false;
          this.formModal = false;
          this.getAgents();
        })
        .catch((err) => {
          let message = this.$t("Unable to add agent");
          if (this.editMode) message = this.$t("Unable to update agent");
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitLoading = false;
          console.log(err);
        });
    },
    getCompanies(search, loading) {
      if (typeof this.companiesCancelToken != typeof undefined) {
        this.companiesCancelToken.cancel(
          "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.companiesCancelToken = this.$http.CancelToken.source();

      let data = {
        search: search,
        per_page: 10,
        page: 1,
      };

      const requestData = {
        method: "get",
        url: "companies",
        params: data,
        cancelToken: this.companiesCancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data.data;
          this.companies = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateEmail() {
      return String(this.agentInput.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

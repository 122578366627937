
// axios
import axios from 'axios'
import StorageService from "@/services/storage";
// import Vue from 'vue'
// import Toast from 'vue-toastification'

// // Toast Notification Component Styles
// import '@core/scss/vue/libs/toastification.scss'

// /**
//  * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
//  */
// Vue.use(Toast, {
//   hideProgressBar: true,
//   pauseOnFocusLoss: true,
//   pauseOnHover: true,
//   closeOnClick: true,
//   closeButton: "button",
//   timeout: 4000,
//   transition: 'Vue-Toastification__fade',
// })

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://api-nanook-staging.defraged.com/api/v1/admin/',
  // baseURL: 'https://mock.nanook.local/api/v1/admin/',
  // timeout: 1000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + StorageService.getToken(),
    'Access-Control-Allow-Origin': '*',
    "Accept": "application/json",
    "locale" : StorageService.getLanguage()
    // "Content-Type": "application/json",
  }
})
axiosIns.interceptors.response.use(response => {

  if (response.data.status != 'success')
    throw response.data.message || response.data.data || 'Request failed';
  return response;
}, error => {
  // console.log(error.response);
  // let message = error.response.data.
  if (error.response.status == 401 || error.response.status == 403) {
    StorageService.setToken('');
    StorageService.setUserData('');
    window.location.href = '/login';
  }

  let errorData = error.response.data;
  if (!errorData.message && errorData.data) {

    if (Array.isArray(errorData.data)) {

      error.response.data.message = errorData.data[0];
    }
    else if (typeof errorData.data === 'string') {
      error.response.data.message = errorData.data;
    }
    else if (typeof errorData.data === 'object') {
      error.response.data.message ='';
      Object.values(errorData.data).forEach(element => {
        if (typeof element === 'string')
          error.response.data.message += `${element} \n`;
        else if (Array.isArray(element) && element.length > 0)
          error.response.data.message += `${element[0]} \n`;

      });
      // error.response.data.message = errorData.data;
    }

  }
  // Vue.$toast.error( error.response.data.message || 'Request failed!' );
  return Promise.reject(error);
});
axiosIns.CancelToken = axios.CancelToken;
axiosIns.isCancel = axios.isCancel;
export default axiosIns

export default [
  {
    title: 'events',
    route: 'home',
    icon: 'MailIcon',
  },
  
  {
    title: 'agents',
    route: 'agents',
    icon: 'UsersIcon',
  },
  {
    title: 'companies',
    route: 'companies',
    icon: 'CheckSquareIcon',
  },
]

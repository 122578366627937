<template>
  <div>
    <div>
      <b-row>
        <b-col md="7">
          <b-row>
            <b-col md="8">
              <div class="d-flex flex-column">
                <span class="mr-1">{{ $t("Choose from the list") }}</span>
                <b-form-group class="mb-0">
                  <multiselect
                    @input="syncAgents"
                    v-model="selectedAgents"
                    :options="allAgents"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder=""
                    label="full_name"
                    track-by="full_name"
                    :preselect-first="true"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} {{ $t("options selected") }}</span
                      ></template
                    >
                  </multiselect>
                </b-form-group>
              </div>
            </b-col>

            <b-col
              md="4"
              class="d-flex justify-content-between align-items-center pt-2"
            >
              <span class="fontSize1-1Rem font-weight-bold text-uppercase">
                {{ $t("or") }}
              </span>
              <b-button
                size="sm"
                @click="showFormModal(null)"
                class=" "
                variant="primary"
              >
                <feather-icon size="16" icon="PlusIcon" class="mr-50" />
                {{ $t("Add a new agent") }}
              </b-button>
            </b-col>
          </b-row>
          <div v-if="syncLoading" class="d-flex align-items-center mt-1">
            <b-spinner
              class="mr-1"
              variant="success"
              small
              style="width: 1.3rem; height: 1.3rem"
            />
            <span class="sr-only">Loading...</span>
            <span class="text-success">{{ $t("Syncing agents") }}...</span>
          </div>
          <div v-else>
            <div class="d-flex align-items-center mt-1" v-if="syncFailed">
              <span @click="syncAgents" class="cursor-pointer text-danger">{{
                $t("Synchronization failed, click to try again")
              }}</span>
            </div>
          </div>
        </b-col>
        <b-col md="5">
          <search
            ref="agentsSearchComponent"
            class="w-100 mt-1"
            v-model="searchInput"
          />
        </b-col>
      </b-row>

      <b-card body-class="p-0" class="mt-1">
        <div>
          <table-head
            ref="agentsTableHeadComponent"
            :items="tableHeadings"
            @sort="sort"
          />
          <b-row
            class="py-1 mx-1 border-bottom"
            v-for="agent in agents"
            :key="agent.id"
          >
            <b-col class="d-flex align-items-center pl-0">
              {{ agent.identifier }}
            </b-col>
            <b-col class="d-flex align-items-center">
              {{ agent.user.first_name }}
            </b-col>
            <b-col class="d-flex align-items-center">
              {{ agent.user.last_name }}
            </b-col>

            <b-col class="d-flex align-items-center">
              {{ agent.user.email }}
            </b-col>
            <b-col class="d-flex align-items-center">
              {{ agent.company ? agent.company.name : "-" }}
            </b-col>
            <b-col class="d-flex align-items-center">
              <b-button
                @click="showConfirmModal(agent)"
                variant="flat-danger"
                class="font-weight-bolder"
              >
                <feather-icon icon="Trash2Icon" size="20" />
              </b-button>
              <b-button
                @click="showFormModal(agent)"
                variant="flat-secondary"
                class="font-weight-bolder"
              >
                <feather-icon icon="Edit2Icon" size="20" />
              </b-button>
              <invite-btn
                @updatePassedStep="$emit('goNext')"
                :agent="agent"
                :event="event"
              />

              <copy-invitation :agent="agent" :event="event" />
            </b-col>
          </b-row>
          <pagination
            class="mt-2"
            ref="agentsPaginationComponent"
            v-model="page"
            :paginationData="paginationData"
          />
        </div>
      </b-card>
    </div>
    <b-row class="mt-5">
      <b-col class="text-right" cols="12">
        <b-button
          class="text-capitalize"
          @click="$emit('goBack')"
          variant="outline-primary"
        >
          {{ "back" }}
        </b-button>
        <b-button @click="sendInvitation()" class="mx-1 px-2" variant="primary">
          <div v-if="invitationLoading" class="px-5">
            <b-spinner
              class="mx-2"
              small
              style="width: 1.3rem; height: 1.3rem"
            />
            <span class="sr-only">Loading...</span>
          </div>
          <span v-else>
            {{ $t("Send invitation to all agents & Finish") }}
          </span>
        </b-button>
      </b-col>
    </b-row>
    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? $t("edit agent") : $t("add agent") }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-3">
          <label for="agent_identifier">Identifier:</label>
          <b-form-input
            id="agent_identifier"
            v-model="agentInput.identifier"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-3">
          <label for="agent_first_name" class="text-capitalize"
            >{{ $t("first name") }}:</label
          >
          <b-form-input
            id="agent_first_name"
            v-model="agentInput.first_name"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-3">
          <label for="agent_last_name" class="text-capitalize"
            >{{ $t("last name") }}:</label
          >
          <b-form-input
            id="agent_last_name"
            v-model="agentInput.last_name"
            type="text"
          />
        </b-form-group>

        <b-form-group class="mt-3">
          <label for="agent_location" class="text-capitalize"
            >{{ $t("email") }}:</label
          >
          <b-form-input
            id="agent_location"
            v-model="agentInput.email"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-3">
          <label for="agent_company" class="text-capitalize"
            >{{ $t("company") }}:</label
          >
          <v-select
            id="agent_company"
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="agentInput.company_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :reduce="(company) => company.id"
            :options="companies"
            @search="getCompanies"
            placeholder="Type to search"
          />
        </b-form-group>
        <b-form-group class="mt-3">
          <label for="agent_language" class="text-capitalize"
            >{{ $t("language") }}:</label
          >
          <v-select
            id="agent_language"
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="agentInput.language"
            label="title"
            :reduce="(lang) => lang.code"
            :options="languages"
            placeholder="Select language"
          />
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import InviteBtn from "@/components/events/InviteBtn.vue";
import CopyInvitation from "@/components/events/CopyInvitation.vue";
import Multiselect from "vue-multiselect";

export default {
  props: ["event", "step"],
  components: {
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    InviteBtn,
    Multiselect,
    CopyInvitation,
  },
  data() {
    return {
      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      perPage: 10,
      page: 1,
      agents: [],
      selectedAgents: [],
      allAgents: [],
      cancelToken: undefined,
      selectCancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      tableHeadings: [
        {
          title: "identifier",
          slug: "identifier",
          sortable: true,
        },
        {
          title: "name",
          slug: "first_name",
          sortable: true,
        },
        {
          title: "last name",
          slug: "last_name",
          sortable: true,
        },
        {
          title: "email",
          slug: "email",
          sortable: true,
        },
        {
          title: "company",
          slug: "company_name",
          sortable: true,
        },

        {
          title: "actions",
          sortable: false,
        },
      ],
      agentInput: {
        id: "",
        first_name: "",
        identifier: "",
        last_name: "",
        email: "",
        language: "en",
        company_id: null,
      },
      editMode: false,
      formModal: false,
      submitLoading: false,
      syncLoading: false,
      syncFailed: false,
      invitationLoading: false,

      companies: [],
      companiesCancelToken: undefined,
      languages: [
        {
          title: "English",
          code: "en",
        },
        {
          title: "Spanish",
          code: "es",
        },
      ],
    };
  },
  mounted() {
    this.getAgents();
    this.getAllAgents();
    this.getCompanies();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getAgents();
    },
    perPage(value) {
      this.page = 1;
      this.getAgents();
    },
    page(value) {
      this.getAgents();
    },
    // selectedAgents(value) {
    //   this.syncAgents();
    // },
  },
  methods: {
    getCompanies(search, loading) {
      if (typeof this.companiesCancelToken != typeof undefined) {
        this.companiesCancelToken.cancel(
          "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.companiesCancelToken = this.$http.CancelToken.source();

      let data = {
        search: search,
        per_page: 10,
        page: 1,
      };

      const requestData = {
        method: "get",
        url: "companies",
        params: data,
        cancelToken: this.companiesCancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data.data;
          this.companies = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendInvitation() {
      if (this.invitationLoading) return;

      this.invitationLoading = true;

      const requestData = {
        method: "post",
        url: `events/${this.event.id}/send-invitation-to-all`,
      };

      this.$http(requestData)
        .then((response) => {
          this.invitationLoading = false;
          this.$emit("showToast", {
            variant: "success",
            message: this.$t(
              "Invitations sent successfully, Redirecting to events page"
            ),
          });

          this.$emit("addPassedStep", this.step);
          this.$emit("goNext");
          setTimeout(() => {
            this.$router.push("/");
          }, 3000);
        })
        .catch((err) => {
          let message = this.$t(`Unable to send invitations!`);
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$emit("showToast", {
            variant: "danger",
            message,
          });

          this.invitationLoading = false;
          console.log(err);
        });
    },

    syncAgents() {
      if (this.syncLoading) return;

      this.syncLoading = true;
      this.selectedAgents = [...new Set(this.selectedAgents)];
      let selectedAgentsIds = [];
      for (let index = 0; index < this.selectedAgents.length; index++) {
        const element = this.selectedAgents[index];
        selectedAgentsIds.push(element.id);
      }
      // for (let index = 0; index < this.agents.length; index++) {
      //   const element = this.agents[index];
      //   selectedAgentsIds.push(element.id);
      // }

      selectedAgentsIds = [...new Set(selectedAgentsIds)];
      const requestData = {
        method: "post",
        url: `events/${this.event.id}/sync-agents`,
        data: {
          selectedAgents: selectedAgentsIds,
        },
      };

      this.$http(requestData)
        .then((response) => {
          this.syncLoading = false;
          this.syncFailed = false;
          if (this.agents && this.agents.length > 0) {
            this.$refs.agentsPaginationComponent.clear();
            this.$refs.agentsSearchComponent.clear();
            this.$refs.agentsTableHeadComponent.clear();
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }

          this.getAgents();
        })
        .catch((err) => {
          this.syncLoading = false;
          this.syncFailed = true;
          console.log(err);
        });
    },

    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.agentInput.identifier) {
        errorMessage = this.$t("Please enter agent identifier");
      }
      if (!errorMessage && !this.agentInput.first_name) {
        errorMessage = this.$t("Please enter agent first name");
      }
      if (!errorMessage && this.agentInput.first_name.length < 2) {
        errorMessage = this.$t(
          "Agent first name should be at least 2 characters"
        );
      }
      if (!errorMessage && !this.agentInput.last_name) {
        errorMessage = this.$t("Please enter agent last name");
      }

      if (!errorMessage && this.agentInput.last_name.length < 2) {
        errorMessage = this.$t(
          "Agent last name should be at least 2 characters"
        );
      }

      if (!errorMessage && !this.agentInput.email) {
        errorMessage = this.$t("Please enter agent email");
      }
      if (!errorMessage && !this.validateEmail()) {
        errorMessage = this.$t("Please enter a valid email address");
      }

      if (!errorMessage && !this.agentInput.company_id) {
        errorMessage = this.$t("Please select company");
      }

      if (errorMessage) {
        this.$emit("showToast", { variant: "danger", message: errorMessage });

        return;
      }

      this.submitLoading = true;

      const requestData = {
        method: this.editMode ? "patch" : "post",
        url: this.editMode
          ? `events/${this.event.id}/agents/${this.agentInput.id}`
          : `events/${this.event.id}/agents`,
        data: this.agentInput,
      };

      this.$http(requestData)
        .then((response) => {
          let message = this.$t("Agent added successfully");
          if (this.editMode) message = this.$t("Agent updated successfully");
          this.$emit("showToast", { variant: "success", message });
          if (!this.editMode && this.agents && this.agents.length > 0) {
            this.$refs.agentsPaginationComponent.clear();
            this.$refs.agentsSearchComponent.clear();
            this.$refs.agentsTableHeadComponent.clear();
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }

          this.submitLoading = false;
          this.formModal = false;
          this.getAgents();
          this.getAllAgents();
        })
        .catch((err) => {
          let message = this.$t("Unable to add agent");
          if (this.editMode) message = this.$t("Unable to update agent");

          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$emit("showToast", { variant: "danger", message });

          this.submitLoading = false;
          console.log(err);
        });
    },
    showFormModal(agent) {
      if (agent) {
        this.agentInput = {
          id: agent.id,
          first_name: agent.user.first_name,
          identifier: agent.identifier,
          last_name: agent.user.last_name,
          email: agent.user.email,
          company_id: agent.company.id,
          language: agent.language || "en",
        };
        this.editMode = true;
      } else {
        this.agentInput = {
          id: "",
          first_name: "",
          identifier: "",
          last_name: "",
          email: "",
          language: "en",
          company_id: null,
        };
        this.editMode = false;
      }
      this.formModal = true;
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getAgents();
    },

    getAllAgents() {
      let data = {
        per_page: 9999,
        page: 1,
      };

      const requestData = {
        method: "get",
        url: "agents",
        params: data,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data.data;
          this.allAgents = data.data;
          this.getEventAllAgents();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEventAllAgents() {
      let data = {
        per_page: 9999,
        page: 1,
      };

      const requestData = {
        method: "get",
        url: `events/${this.event.id}/agents`,
        params: data,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data.data;

          this.selectedAgents = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAgents() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        search: this.searchInput,
        per_page: this.perPage,
        page: this.page,
        sort_by: this.sortBy,
        sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: `events/${this.event.id}/agents`,
        params: data,
        cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data.data;
          this.agents = data.data;
          // this.selectedAgents = data.data;
          this.paginationData = {
            current_page: data.meta.current_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
            per_page: data.meta.per_page,
            last_page: data.meta.last_page,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showConfirmModal(agent) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("Please confirm that you want to delete agent") +
            `: ${agent.user.first_name} ${agent.user.last_name}.`,
          {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (confirmed) => {
          if (confirmed) {
            let agentIndex = 0;
            this.agents = await this.agents.filter((agentItem, index) => {
              if (agentItem.id == agent.id) {
                agentIndex = index;
              }
              return agentItem.id !== agent.id;
            });

            const requestData = {
              method: "delete",
              url: `events/${this.event.id}/agents/${agent.id}`,
            };
            this.$http(requestData)
              .then((response) => {
                this.$emit("showToast", {
                  variant: "success",
                  message: `Agent: ${agent.identifier} deleted successfully.`,
                });
                this.getAllAgents();
              })
              .catch((err) => {
                let message = this.$t("Unable to delete!");
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$emit("showToast", {
                  variant: "danger",
                  message,
                });

                this.agents.splice(agentIndex, 0, agent);
              });
          }
        });
    },
    validateEmail() {
      return String(this.agentInput.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

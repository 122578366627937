<template>
  <div>
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center w-50">
          <b-button
            @click="showFormModal(null)"
            class="text-capitalize px-1 mr-1"
            variant="primary"
          >
            <feather-icon size="16" icon="PlusIcon" class="mr-50" />
            {{ $t("add record") }}
          </b-button>
          <b-button
            @click="showImportCsv = true"
            class="text-capitalize px-1"
            variant="primary"
          >
            <feather-icon size="16" icon="DownloadIcon" class="mr-50" />
            {{ $t("import csv") }}
          </b-button>
        </div>

        <search
          ref="productsSearchComponent"
          class="w-50"
          v-model="searchInput"
        />
      </div>

      <b-card body-class="p-0" class="mt-1">
        <div>
          <table-head
            :items="tableHeadings"
            @sort="sort"
            ref="productsTableHeadComponent"
          />
          <b-row
            class="py-1 mx-1 border-bottom"
            v-for="product in products"
            :key="product.id"
          >
            <b-col class="d-flex align-items-center pl-0">
              <div class="mr-1">
                <img
                  v-if="product.imageUrl"
                  height="30"
                  :src="product.imageUrl"
                  alt=""
                  srcset=""
                />
                <b-avatar
                  v-else
                  :text="product.model.substring(0, 2)"
                  size="50"
                ></b-avatar>
              </div>
              <div class="d-flex flex-column">
                <b>
                  {{ product.model }}
                </b>
              </div>
            </b-col>
            <b-col class="d-flex align-items-center">
              {{ product.color }}
            </b-col>
            <b-col class="d-flex align-items-center">
              {{ product.identifier }}
            </b-col>
            <b-col class="d-flex align-items-center">
              <b-button
                @click="showConfirmModal(product)"
                variant="flat-danger"
                class="font-weight-bolder"
              >
                <feather-icon icon="Trash2Icon" size="20" />
              </b-button>
              <b-button
                @click="showFormModal(product)"
                variant="flat-secondary"
                class="font-weight-bolder"
              >
                <feather-icon icon="Edit2Icon" size="20" />
              </b-button>
            </b-col>
          </b-row>
          <pagination
            class="mt-2"
            ref="productsPaginationComponent"
            v-model="page"
            :paginationData="paginationData"
          />
        </div>
      </b-card>
    </div>
    <b-row>
      <b-col class="text-right" cols="12">
        <b-button
          class="text-capitalize"
          @click="$emit('goBack')"
          variant="outline-primary"
        >
          {{ $t("back") }}
        </b-button>
        <b-button
          @click="$emit('goNext')"
          class="text-capitalize mx-1 px-3"
          variant="primary"
        >
          {{ $t("next") }}
        </b-button>
      </b-col>
    </b-row>
    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{
                editMode ? $t("edit product model") : $t("add product model")
              }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-3">
          <label for="product_model" class="text-capitalize"
            >{{ $t("model") }}:</label
          >
          <b-form-input
            id="product_model"
            v-model="productInput.model"
            type="text"
          />
        </b-form-group>

        <b-form-group class="mt-3">
          <label for="product_color" class="text-capitalize"
            >{{ $t("color") }}:</label
          >
          <b-form-input
            id="product_color"
            v-model="productInput.color"
            type="text"
          />
        </b-form-group>

        <b-form-group class="mt-3">
          <label for="product_identifier" class="text-capitalize"
            >{{ $t("identifier") }}:</label
          >
          <b-form-input
            id="product_identifier"
            v-model="productInput.identifier"
            type="text"
          />
        </b-form-group>
        <div class="d-flex flex-column justify-content-between mt-3">
          <span class="text-capitalize">{{ $t("image") }}:</span>
          <file-picker
            class=""
            ref="imagePicker"
            :url="productInput.imageUrl"
            v-model="imageFile"
          />
        </div>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span class="text-capitalize" v-else>
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <b-sidebar
      width="500px"
      v-model="showImportCsv"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="showImportCsv = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">{{ $t("import csv") }}</h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-5">
          <div class="d-flex justify-content-start align-items-center ">
            <label for="product_file" class="text-capitalize mr-4 fontSize1Rem"
              >{{ $t("csv file") }}:</label
            >
            <a class="fontSize0-9Rem" target="_blank"  href="/sample.csv"> {{ $t("Download sample file") }} </a>
          </div>

          <b-form-file
            accept="text/csv"
            id="product_file"
            class="py-5 d-flex justify-content-center align-items-center productModelsCsvInput"
            v-model="csvFile"
            placeholder="Drop csv file here or click to upload"
            drop-placeholder="Drop csv file here or click to upload"
          ></b-form-file>
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="showImportCsv = false"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="uploadCsvFile()">
            <div v-if="csvUploadLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span class="text-capitalize" v-else>
              {{ $t("upload") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormFile,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import FilePicker from "@/components/FilePicker.vue";
export default {
  props: ["event", "step"],
  components: {
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormFile,
    FilePicker,
  },
  data() {
    return {
      perPage: 10,
      page: 1,
      products: [],
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      tableHeadings: [
        {
          title: "model",
          slug: "model",
          sortable: true,
        },
        {
          title: "color",
          slug: "color",
          sortable: true,
        },
        {
          title: "identifier",
          slug: "identifier",
          sortable: true,
        },

        {
          title: "actions",
          sortable: false,
        },
      ],
      productInput: {
        model: "",
        identifier: "",
        color: "",
        imageUrl: "",
      },
      imageFile: null,
      editMode: false,
      formModal: false,
      submitLoading: false,

      showImportCsv: false,
      csvUploadLoading: false,
      csvFile: null,
    };
  },
  mounted() {
    this.getProducts();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getProducts();
    },
    perPage(value) {
      this.page = 1;
      this.getProducts();
    },
    page(value) {
      this.getProducts();
    },
  },
  methods: {
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.productInput.model) {
        errorMessage = this.$t("Please enter product model");
      }
      if (!errorMessage && !this.productInput.color) {
        errorMessage = this.$t("Please enter product color");
      }
      if (!errorMessage && !this.productInput.identifier) {
        errorMessage = this.$t("Please enter product identifier");
      }
      if (!errorMessage && !this.productInput.imageUrl && !this.imageFile) {
        errorMessage = this.$t("Please select an image");
      }
      if (errorMessage) {
        this.$emit("showToast", { variant: "danger", message: errorMessage });

        return;
      }

      this.submitLoading = true;
      let formData = new FormData();
      if (this.imageFile) formData.append("imageFile", this.imageFile);
      for (const [key, value] of Object.entries({
        ...this.productInput,
      })) {
        formData.append(key, value);
      }

      const requestData = {
        method: "post",
        url: this.editMode
          ? `events/${this.event.id}/products/${this.productInput.id}/update`
          : `events/${this.event.id}/products`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$http(requestData)
        .then(async (response) => {
          let message = this.$t("Product model added successfully");
          if (this.editMode)
            message = this.$t(`Product model updated successfully`);
          this.$emit("showToast", { variant: "success", message });

          if (!this.editMode && this.products.length > 0) {
            this.$refs.productsPaginationComponent.clear();
            this.$refs.productsSearchComponent.clear();
            this.$refs.productsTableHeadComponent.clear();
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }

          this.submitLoading = false;
          this.formModal = false;
          this.$emit("addPassedStep", this.step);
          this.getProducts();
        })
        .catch((err) => {
          let message = this.$t("Unable to add product");

          if (this.editMode) message = this.$t(`Unable to update product`);

          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$emit("showToast", { variant: "danger", message });

          this.submitLoading = false;
          console.log(err);
        });
    },
    uploadCsvFile() {
      if (this.csvUploadLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.csvFile) {
        errorMessage = this.$t("Please select csv file");
      }

      if (errorMessage) {
        this.$emit("showToast", { variant: "danger", message: errorMessage });

        return;
      }

      this.csvUploadLoading = true;
      let formData = new FormData();
      formData.append("file", this.csvFile);
      const requestData = {
        method: "post",
        url: `events/${this.event.id}/upload-products-csv`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$http(requestData)
        .then(async (response) => {
          let message = this.$t("Csv file imported successfully");
          this.$emit("showToast", { variant: "success", message });

          if (this.products.length > 0) {
            this.$refs.productsPaginationComponent.clear();
            this.$refs.productsSearchComponent.clear();
            this.$refs.productsTableHeadComponent.clear();
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }

          this.csvUploadLoading = false;
          this.showImportCsv = false;
          this.$emit("addPassedStep", this.step);
          this.getProducts();
        })
        .catch((err) => {
          let message = this.$t("Unable to import csv file");
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;
          this.$emit("showToast", { variant: "danger", message });

          this.csvUploadLoading = false;
          console.log(err);
        });
    },

    showFormModal(product) {
      if (product) {
        this.productInput = { ...product };
        this.editMode = true;
      } else {
        this.productInput = {
          model: "",
          identifier: "",
          color: "",
          image: "",
        };
        this.editMode = false;
      }
      this.imageFile = null;
      this.$refs.imagePicker.clearFileInput();
      this.formModal = true;
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getProducts();
    },

    async getProducts() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        search: this.searchInput,
        per_page: this.perPage,
        page: this.page,
        sort_by: this.sortBy,
        sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: `events/${this.event.id}/products`,
        params: data,
        cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data.data;
          this.products = data.data;
          this.paginationData = {
            current_page: data.meta.current_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
            per_page: data.meta.per_page,
            last_page: data.meta.last_page,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showConfirmModal(product) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("Please confirm that you want to delete product") +
            `: ${product.identifier}.`,
          {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (confirmed) => {
          if (confirmed) {
            let productIndex = 0;
            this.products = await this.products.filter((productItem, index) => {
              if (productItem.id == product.id) {
                productIndex = index;
                console.log("found");
              }
              return productItem.id !== product.id;
            });

            const requestData = {
              method: "delete",
              url: `events/${this.event.id}/products/${product.id}`,
            };
            this.$http(requestData)
              .then((response) => {
                this.$emit("showToast", {
                  variant: "success",
                  message: this.$t(`Product deleted successfully`),
                });
              })
              .catch((err) => {
                let message = this.$t("Unable to delete!");
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$emit("showToast", {
                  variant: "danger",
                  message,
                });

                this.products.splice(productIndex, 0, product);
              });
          }
        });
    },
  },
};
</script>

<style lang="scss">
.productModelsCsvInput {
  .custom-file-label {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%23D8D6DEFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 0.357rem;
    border: none;
    color: #d8d6de;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      display: none !important;
    }
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import StorageService from '@/services/storage'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/events/Index.vue'),
      meta: {
        pageTitle: 'events',
        requiresAuth : true,
        breadcrumb: [
          {
            text: 'events',
            active: true,
          },
        ],
      },
    },
    {
      path: '/events/create',
      name: 'createEvent',
      component: () => import('@/views/events/Create.vue'),
      meta: {
        navActiveLink : 'home',
        pageTitle: 'create event',
        requiresAuth : true,
        breadcrumb: [
          {
            text: 'events',
          },
          {
            text: 'new event',
            active: true,
          },
        ],
      },
    },
    {
      path: '/events/:id/details',
      name: 'eventDetails',
      component: () => import('@/views/events/Details.vue'),
      meta: {
        navActiveLink : 'home',
        pageTitle: 'event details',
        requiresAuth : true,
        breadcrumb: [
          {
            text: 'events',
          },
          {
            text: 'details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/events/:id/edit',
      name: 'eventEdit',
      component: () => import('@/views/events/Edit.vue'),
      meta: {
        navActiveLink : 'home',
        pageTitle: 'edit event',
        requiresAuth : true,
        breadcrumb: [
          {
            text: 'events',
          },
          {
            text: 'edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/assignments/:id',
      name: 'assignmentDetails',
      component: () => import('@/views/assignments/Details.vue'),
      meta: {
        navActiveLink : 'home',
        pageTitle: 'assignment details',
        requiresAuth : true,
        breadcrumb: [
          {
            text: 'events',
          },
          {
            text: 'details',
          },
          {
            text: 'assignment details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/agents',
      name: 'agents',
      component: () => import('@/views/Agents.vue'),
      meta: {
        pageTitle: 'agents',
        requiresAuth : true,
        breadcrumb: [
          {
            text: 'agents',
            active: false,
          },
        ],
      },
    },
    {
      path: '/companies',
      name: 'companies',
      component: () => import('@/views/Companies.vue'),
      meta: {
        pageTitle: 'companies',
        requiresAuth : true,
        breadcrumb: [
          {
            text: 'companies',
            active: false,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        requiresAuth : true,
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        guest : true,
       
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        requiresAuth : false,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  let token   = StorageService.getToken();
  if(to.matched.some(record => record.meta.requiresAuth)) {
     
      if (!token) {
       
          next({
              path: '/login',
              params: { nextUrl: to.fullPath }
          })
      } else {
          next()
        
      }
  } else if(to.matched.some(record => record.meta.guest)) {
      if(!token){
          next()
      }
      else{
          next({ name: 'home'})
      }
  }else {
      next()
  }
})


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

<template>
  <b-button
    @click="sendInvitation(agent)"
    variant="flat-secondary"
    class="font-weight-bolder"
  >
    <div
      v-if="loading"
      class=""
    >
      <b-spinner
        small
        style="width: 1.3rem; height: 1.3rem;"
      />
      <span class="sr-only">Loading...</span>
    </div>
    <feather-icon
      v-else
      icon="MailIcon"
      size="20"
    />

  </b-button>
</template>

<script>
  import { BButton, BSpinner } from "bootstrap-vue";
  export default {
    props: ["agent", "event"],
    components: {
      BSpinner,
      BButton,
    },
    data() {
      return {
        loading: false,
      };
    },
    methods: {
      sendInvitation(agent) {
        if (this.loading) return;

        this.loading = true;

        const requestData = {
          method: "post",
          url: `events/${this.event.id}/send-invitation`,
          data: {
            agent: agent.id,
          },
        };

        this.$http(requestData)
          .then((response) => {
            this.loading = false;
            this.$bvToast.toast(
               this.$t("Invitation sent successfully to") +`: ${agent.user.first_name} ${agent.user.last_name}`,
              {
                title: this.$t("Done"),
                variant: "success",
                solid: true,
              }
            );
            this.$emit("updatePassedStep");
          })
          .catch((err) => {
            let message = this.$t(`Unable to send invitation!`);
            if (err.response && err.response.data && err.response.data.message)
              message = err.response.data.message;
            this.$bvToast.toast(message, {
              title: this.$t("Error"),
              variant: "danger",
              solid: true,
            });
            this.loading = false;
            console.log(err);
          });
      },
    },
  };
</script>

<style>
</style>
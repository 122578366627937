<template>
  <div>

    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center w-50">
          <b-button
            @click="showFormModal(null)"
            class="text-capitalize px-1"
            variant="primary"
          >
            <feather-icon
              size="16"
              icon="PlusIcon"
              class="mr-50"
            />
             {{$t("add record")}}
          </b-button>
          <div
            v-if="sortLoading"
            class="d-flex align-items-center   ml-2"
          >
            <b-spinner
              class="mr-25"
              variant="success"
              small
              style="width: 1.3rem; height: 1.3rem;"
            />
            <span class="sr-only">Loading...</span>
            <span class="text-success">{{$t("Saving sort order")}}...</span>
          </div>
          <div v-else>
            <div
              class="d-flex align-items-center  ml-2"
              v-if="sortFailed"
            >
              <span
                @click="orderChangeHandler"
                class=" cursor-pointer text-danger"
              >{{$t("Saving failed, click to try again")}}</span>
            </div>
          </div>
        </div>

        <search
          ref="questionsSearchComponent"
          class=" w-50"
          v-model="searchInput"
        />

      </div>

      <b-card
        body-class="p-0"
        class=" mt-1"
      >
        <div>
          <table-head
            :items="tableHeadings"
            @sort="sort"
            ref="questionsTableHeadComponent"
          />
          <draggable
            @start="isDragging=true"
            @end="isDragging=false"
            :list="questions"
            :move="orderChangeHandler"
          >
            <b-row
              class="py-1 mx-1 border-bottom cursor-move"
              v-for="(question, index) in questions"
              :key="question.id"
            >
              <b-col class="d-flex align-items-center pl-0">
                <feather-icon
                  icon="MoveIcon"
                  size="20"
                  class="mr-1"
                />

                {{getShortContent(question.content)}}

              </b-col>
              <b-col class="d-flex align-items-center">

                {{index+1}}

              </b-col>

              <b-col class="d-flex align-items-center">
                <b-button
                  @click="showConfirmModal(question)"
                  variant="flat-danger"
                  class="font-weight-bolder"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="20"
                  />
                </b-button>
                <b-button
                  @click="showFormModal(question)"
                  variant="flat-secondary"
                  class="font-weight-bolder"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    size="20"
                  />
                </b-button>
              </b-col>

            </b-row>
          </draggable>
          <pagination
            class="mt-2"
            ref="questionsPaginationComponent"
            v-model="page"
            :paginationData="paginationData"
          />
        </div>

      </b-card>

    </div>
    <b-row class="mt-5">
      <b-col
        class=" text-right"
        cols="12"
      >
        <b-button
          @click="$emit('goBack')"
          variant="outline-primary"
          class="text-capitalize"
        >
          {{$t("back")}}
        </b-button>
        <b-button
          @click="$emit('goNext')"
          class="text-capitalize mx-1 px-3"
          variant="primary"
        >

          {{$t("next")}}

        </b-button>
      </b-col>
    </b-row>
    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon
                icon="XIcon"
                size="20"
              />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3>{{ editMode ? $t('edit question') : $t('add question')}} </h3>

          </div>
        </div>
      </template>
      <b-container class="">

        <b-form-group class="mt-3">
          <label for="question_content" class="text-capitalize">{{$t("content")}}:</label>
          <b-form-input
            id="question_content"
            v-model="questionInput.content"
            type="text"
          />
        </b-form-group>

      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2  py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
            {{$t("cancel")}}
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm()"
          >
            <div
              v-if="submitLoading"
              class="px-1"
            >
              <b-spinner
                small
                style="width: 1.3rem; height: 1.3rem;"
              />
              <span class="sr-only">Loading...</span>
            </div>
           <span class="text-capitalize" v-else>
              
                {{$t("submit")}}
            </span>

          </b-button>

        </div>
      </template>
    </b-sidebar>
  </div>

</template>

<script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    BSpinner,
    BSidebar,
    BContainer,
  } from "bootstrap-vue";
  import PerPage from "@/components/PerPage.vue";
  import Breadcrumb from "@/components/Breadcrumb.vue";
  import Pagination from "@/components/Pagination.vue";
  import Search from "@/components/Search.vue";
  import TableHead from "@/components/TableHead.vue";
  import draggable from "vuedraggable";

  export default {
    props: ["event", "step"],
    components: {
      BRow,
      BCol,
      PerPage,
      BFormGroup,
      BFormInput,
      BCard,
      BAvatar,
      BButton,
      Breadcrumb,
      Pagination,
      Search,
      TableHead,
      BSpinner,
      BSidebar,
      BContainer,
      draggable,
    },
    data() {
      return {
        perPage: 100,
        page: 1,
        questions: [],
        cancelToken: undefined,
        sortCancelToken: undefined,
        sortBy: "",
        sortDir: "",
        searchInput: "",
        paginationData: null,
        tableHeadings: [
          {
            title: "content",
            slug: "content",
            sortable: false,
          },
          {
            title: "Order",
            slug: "order",
            sortable: false,
          },

          {
            title: "actions",
            sortable: false,
          },
        ],
        questionInput: {
          content: "",
          order: 1,
        },
        editMode: false,
        formModal: false,
        submitLoading: false,
        isDragging: false,
        sortLoading: false,
        sortFailed: false,
      };
    },
    mounted() {
      this.getQuestions();
    },
    watch: {
      searchInput(value) {
        this.page = 1;
        this.getQuestions();
      },
      perPage(value) {
        this.page = 1;
        this.getQuestions();
      },
      page(value) {
        this.getQuestions();
      },
    },
    methods: {
      orderChangeHandler() {
        this.sortLoading = true;
        setTimeout(() => {
          if (typeof this.sortCancelToken != typeof undefined) {
          this.sortCancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.sortCancelToken = this.$http.CancelToken.source();
        const requestData = {
          method: "post",
          url: `events/${this.event.id}/sort-questions`,
          data: {
            questions: this.questions,
          },
          cancelToken: this.sortCancelToken.token,
        };

        this.$http(requestData)
          .then(async (response) => {
            this.sortLoading = false;
            this.sortFailed = false;
          })
          .catch((err) => {
            this.sortLoading = false;
            this.sortFailed = true;
            console.log(err);
          });
        }, 200);
        
      },
      submitForm() {
        if (this.submitLoading) return;
        let errorMessage = null;
        if (!errorMessage && !this.questionInput.content) {
          errorMessage = this.$t("Please enter question content");
        }

        if (errorMessage) {
          this.$emit("showToast", { variant: "danger", message: errorMessage });

          return;
        }

        this.submitLoading = true;

        const requestData = {
          method: this.editMode ? "patch" : "post",
          url: this.editMode
            ? `events/${this.event.id}/questions/${this.questionInput.id}`
            : `events/${this.event.id}/questions`,
          data: this.questionInput,
        };

        this.$http(requestData)
          .then(async (response) => {
            let message = this.$t("Question added successfully");
            if (this.editMode) message = this.$t(`Question updated successfully`);
            this.$emit("showToast", { variant: "success", message });

            if (!this.editMode && this.questions.length > 0) {
              this.$refs.questionsPaginationComponent.clear();
              this.$refs.questionsSearchComponent.clear();
              this.$refs.questionsTableHeadComponent.clear();
              this.page = 1;
              this.searchInput = "";
              this.sortBy = "";
              this.sortDir = "";
            }

            this.submitLoading = false;
            this.formModal = false;
            this.$emit("addPassedStep", this.step);
            this.getQuestions();
          })
          .catch((err) => {
            console.log(err);
            let message = this.$t("Unable to add question");
            if (this.editMode) message = this.$t(`Unable to update question`);
            if (err.response && err.response.data && err.response.data.message)
              message = err.response.data.message;
            this.$emit("showToast", { variant: "danger", message });

            this.submitLoading = false;
            console.log(err);
          });
      },
      showFormModal(question) {
        if (question) {
          this.questionInput = { ...question };
          this.editMode = true;
        } else {
          this.questionInput = {
            content: "",
          };
          this.editMode = false;
        }
        this.formModal = true;
      },
      sort(args) {
        this.page = 1;
        this.sortBy = args.sortBy;
        this.sortDir = args.sortDir;
        this.getQuestions();
      },

      async getQuestions() {
        if (typeof this.cancelToken != typeof undefined) {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.cancelToken = this.$http.CancelToken.source();

        let data = {
          search: this.searchInput,
          per_page: this.perPage,
          page: this.page,
          sort_by: this.sortBy,
          sort_dir: this.sortDir,
        };

        const requestData = {
          method: "get",
          url: `events/${this.event.id}/questions`,
          params: data,
          cancelToken: this.cancelToken.token,
        };
        this.$http(requestData)
          .then((response) => {
            let data = response.data.data;
            this.questions = data.data;
            this.paginationData = {
              current_page: data.meta.current_page,
              from: data.meta.from,
              to: data.meta.to,
              total: data.meta.total,
              per_page: data.meta.per_page,
              last_page: data.meta.last_page,
            };
          })
          .catch((err) => {
            console.log(err);
          });
      },
      showConfirmModal(question) {
        this.$bvModal
          .msgBoxConfirm(
            this.$t("Please confirm that you want to delete question") + `: ${this.getShortContent(
              question.content
            )}.`,
            {
              title: this.$t("Please Confirm"),
              size: "sm",
              okVariant: "primary",
              okTitle: this.$t("Yes"),
              cancelTitle: this.$t("No"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then(async (confirmed) => {
            if (confirmed) {
              let questionIndex = 0;
              this.questions = await this.questions.filter(
                (questionItem, index) => {
                  if (questionItem.id == question.id) {
                    questionIndex = index;
                    console.log("found");
                  }
                  return questionItem.id !== question.id;
                }
              );

              const requestData = {
                method: "delete",
                url: `events/${this.event.id}/questions/${question.id}`,
              };
              this.$http(requestData)
                .then((response) => {
                  this.$emit("showToast", {
                    variant: "success",
                    message: this.$t(`Question deleted successfully`),
                  });
                })
                .catch((err) => {
                  let message = this.$t("Unable to delete!");
                  if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                  )
                    message = err.response.data.message;
                  this.$emit("showToast", {
                    variant: "danger",
                    message,
                  });

                  this.questions.splice(questionIndex, 0, question);
                });
            }
          });
      },
      getShortContent(content, length = 30) {
        if (content.length <= length) {
          return content;
        }
        return content.substring(0, length) + " ...";
      },
    },
  };
</script>

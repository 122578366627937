<template>
  <div>

    <div>
      <b-row>
        <b-col md="6">
          <b-row>
            <b-col md="8">
              <div class="d-flex flex-column ">
                <span class="mr-1">{{$t("Choose from the list")}}</span>
                <b-form-group class="mb-0 ">

                  <multiselect
                    @input="syncSalesPoints"
                    v-model="selectedSalesPoints"
                    :options="allSalesPoints"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder=""
                    label="name"
                    track-by="name"
                    :preselect-first="true"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                    ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                      >{{ values.length }} {{$t("options selected")}}</span></template>
                  </multiselect>

                </b-form-group>
              </div>
            </b-col>

            <b-col
              md="4"
              class="d-flex justify-content-between align-items-center pt-2"
            >
              <span class="fontSize1-1Rem font-weight-bold text-uppercase" > {{$t("or")}}</span>
              <b-button
                size="sm"
                @click="showFormModal(null)"
                class=" text-capitalize"
                variant="primary"
              >
                <feather-icon
                  size="16"
                  icon="PlusIcon"
                  class="mr-50"
                />
                {{$t("add record")}}
              </b-button>
            </b-col>
          </b-row>
          <div
            v-if="syncLoading"
            class="d-flex align-items-center  mt-1"
          >
            <b-spinner
              class="mr-1"
              variant="success"
              small
              style="width: 1.3rem; height: 1.3rem;"
            />
            <span class="sr-only">Loading...</span>
            <span class="text-success">{{$t("Syncing salespoints")}}...</span>
          </div>
          <div v-else>
            <div
              class="d-flex align-items-center  mt-1"
              v-if="syncFailed"
            >
              <span
                @click="syncSalesPoints"
                class=" cursor-pointer text-danger"
              >{{$t("Synchronization failed, click to try again")}}</span>
            </div>
          </div>
        </b-col>
        <b-col md="6">
          <search
            ref="salesPointsSearchComponent"
            class=" w-100 mt-1"
            v-model="searchInput"
          />
        </b-col>
      </b-row>

      <b-card
        body-class="p-0"
        class=" mt-1"
      >
        <div>
          <table-head
            ref="salesPointsTableHeadComponent"
            :items="tableHeadings"
            @sort="sort"
          />
          <b-row
            class="py-1 mx-1 border-bottom"
            v-for="salesPoint in salesPoints"
            :key="salesPoint.id"
          >
            <b-col class="d-flex align-items-center pl-0">

              {{salesPoint.name}}

            </b-col>
            <b-col class="d-flex align-items-center">
              {{salesPoint.identifier}}
            </b-col>
            <b-col class="d-flex align-items-center">
              {{salesPoint.description.substring(0,20)}}
            </b-col>

            <b-col class="d-flex align-items-center">
              {{salesPoint.location.substring(0,20)}}
            </b-col>
            <b-col class="d-flex align-items-center">
              <b-button
                @click="showConfirmModal(salesPoint)"
                variant="flat-danger"
                class="font-weight-bolder"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="20"
                />
              </b-button>
              <b-button
                @click="showFormModal(salesPoint)"
                variant="flat-secondary"
                class="font-weight-bolder"
              >
                <feather-icon
                  icon="Edit2Icon"
                  size="20"
                />
              </b-button>
            </b-col>

          </b-row>
          <pagination
            class="mt-2"
            ref="salesPointsPaginationComponent"
            v-model="page"
            :paginationData="paginationData"
          />
        </div>

      </b-card>

    </div>
    <b-row class="mt-5">
      <b-col
        class=" text-right"
        cols="12"
      >
        <b-button
        class="text-capitalize"
          @click="$emit('goBack')"
          variant="outline-primary"
        >
          {{("back")}}
        </b-button>
        <b-button
          @click="$emit('goNext')"
          class="text-capitalize mx-1 px-3"
          variant="primary"
        >
          {{$t("next")}}
        </b-button>
      </b-col>
    </b-row>
    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon
                icon="XIcon"
                size="20"
              />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">{{ editMode ? $t('edit salespoint') : 'add salespoint'}} </h3>

          </div>
        </div>
      </template>
      <b-container class="">

        <b-form-group class="mt-3">
          <label for="salesPoint_name" class="text-capitalize">{{$t("name")}}:</label>
          <b-form-input
            id="salesPoint_name"
            v-model="salesPointInput.name"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-3">
           <label for="salesPoint_identifier" class="text-capitalize">{{$t("identifier")}}:</label>
          <b-form-input
            id="salesPoint_identifier"
            v-model="salesPointInput.identifier"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-3">
          <label for="salesPoint_description" class="text-capitalize">{{$t("description")}}:</label>
          <b-form-textarea
            id="salesPoint_description"
            v-model="salesPointInput.description"
            rows="3"
          />

        </b-form-group>
        <b-form-group class="mt-3">
          <label for="salesPoint_location" class="text-capitalize">{{$t("location")}}:</label>
          <b-form-input
            id="salesPoint_location"
            v-model="salesPointInput.location"
            type="text"
          />
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2  py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
           {{$t("cancel")}}
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm()"
          >
            <div
              v-if="submitLoading"
              class="px-1"
            >
              <b-spinner
                small
                style="width: 1.3rem; height: 1.3rem;"
              />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{$t("submit")}}
            </span>
          </b-button>

        </div>
      </template>
    </b-sidebar>
  </div>

</template>

<script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
  } from "bootstrap-vue";
  import PerPage from "@/components/PerPage.vue";
  import Breadcrumb from "@/components/Breadcrumb.vue";
  import Pagination from "@/components/Pagination.vue";
  import Search from "@/components/Search.vue";
  import TableHead from "@/components/TableHead.vue";
  // import vSelect from "vue-select";
  import Multiselect from "vue-multiselect";
  export default {
    props: ["event", "step"],
    components: {
      BRow,
      BCol,
      PerPage,
      BFormGroup,
      BFormInput,
      BCard,
      BAvatar,
      BButton,
      Breadcrumb,
      Pagination,
      Search,
      TableHead,
      BSpinner,
      BSidebar,
      BContainer,
      BFormTextarea,
      // vSelect,
      Multiselect,
    },
    data() {
      return {
        OpenIndicator: {
          render: (createElement) =>
            createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
        },
        perPage: 10,
        page: 1,
        salesPoints: [],
        selectedSalesPoints: [],
        allSalesPoints: [],
        cancelToken: undefined,
        selectCancelToken: undefined,
        sortBy: "",
        sortDir: "",
        searchInput: "",
        paginationData: null,
        tableHeadings: [
          {
            title: "name",
            slug: "name",
            sortable: true,
          },
          {
            title: "identifier",
            slug: "identifier",
            sortable: true,
          },
          {
            title: "description",
            slug: "description",
            sortable: true,
          },
          {
            title: "location",
            slug: "location",
            sortable: true,
          },

          {
            title: "actions",
            sortable: false,
          },
        ],
        salesPointInput: {
          name: "",
          identifier: "",
          description: "",
          location: "",
        },
        editMode: false,
        formModal: false,
        submitLoading: false,
        syncLoading: false,
        syncFailed: false,
      };
    },
    mounted() {
      this.getSalesPoints();
      this.getAllSalesPoints();
    },
    watch: {
      searchInput(value) {
        this.page = 1;
        this.getSalesPoints();
      },
      perPage(value) {
        this.page = 1;
        this.getSalesPoints();
      },
      page(value) {
        this.getSalesPoints();
      },
      // selectedSalesPoints(value) {
      //   this.syncSalesPoints();
      // },
    },
    methods: {
      syncSalesPoints() {
        if (this.syncLoading) return;

        this.syncLoading = true;
        this.selectedSalesPoints = [...new Set(this.selectedSalesPoints)];
        let selectedSalesPointsIds = [];
        for (let index = 0; index < this.selectedSalesPoints.length; index++) {
          const element = this.selectedSalesPoints[index];
          selectedSalesPointsIds.push(element.id);
        }
        const requestData = {
          method: "post",
          url: `events/${this.event.id}/sync-salespoints`,
          data: {
            selectedSalespoints: selectedSalesPointsIds,
          },
        };

        this.$http(requestData)
          .then((response) => {
            this.syncLoading = false;
            this.syncFailed = false;
            if (this.salesPoints && this.salesPoints.length > 0) {
              this.$refs.salesPointsPaginationComponent.clear();
              this.$refs.salesPointsSearchComponent.clear();
              this.$refs.salesPointsTableHeadComponent.clear();
              this.page = 1;
              this.searchInput = "";
              this.sortBy = "";
              this.sortDir = "";
            }
            this.$emit("addPassedStep", this.step);
            this.getSalesPoints();
          })
          .catch((err) => {
            this.syncLoading = false;
            this.syncFailed = true;
            console.log(err);
          });
      },
      submitForm() {
        if (this.submitLoading) return;
        let errorMessage = null;
        if (!errorMessage && !this.salesPointInput.name) {
          errorMessage = this.$t("Please enter salespoint name");
        }

        if (!errorMessage && !this.salesPointInput.identifier) {
          errorMessage = this.$t("Please enter salespoint identifier");
        }
        if (!errorMessage && !this.salesPointInput.description) {
          errorMessage = this.$t("Please enter salespoint description");
        }
        if (!errorMessage && !this.salesPointInput.location) {
          errorMessage = this.$t("Please enter salespoint location");
        }
        if (errorMessage) {
          this.$emit("showToast", { variant: "danger", message: errorMessage });

          return;
        }

        this.submitLoading = true;

        const requestData = {
          method: this.editMode ? "patch" : "post",
          url: this.editMode
            ? `events/${this.event.id}/salespoints/${this.salesPointInput.id}`
            : `events/${this.event.id}/salespoints`,
          data: this.salesPointInput,
        };

        this.$http(requestData)
          .then((response) => {
            let message = this.$t("Salespoint added successfully");
            if (this.editMode) message = this.$t(`Salespoint updated successfully`);
            this.$emit("showToast", { variant: "success", message });

            if (
              !this.editMode &&
              this.salesPoints &&
              this.salesPoints.length > 0
            ) {
              this.$refs.salesPointsPaginationComponent.clear();
              this.$refs.salesPointsSearchComponent.clear();
              this.$refs.salesPointsTableHeadComponent.clear();
              this.page = 1;
              this.searchInput = "";
              this.sortBy = "";
              this.sortDir = "";
            }

            this.submitLoading = false;
            this.formModal = false;
            this.$emit("addPassedStep", this.step);
            this.getSalesPoints();
            this.getAllSalesPoints();
          })
          .catch((err) => {
            let message = this.$t("Unable to add salespoint");
            if (this.editMode) message = this.$t(`Unable to update salespoint`);
            if (err.response && err.response.data && err.response.data.message)
              message = err.response.data.message;
            this.$emit("showToast", { variant: "danger", message });

            this.submitLoading = false;
            console.log(err);
          });
      },
      showFormModal(salesPoint) {
        if (salesPoint) {
          this.salesPointInput = { ...salesPoint };
          this.editMode = true;
        } else {
          this.salesPointInput = {
            name: "",
            identifier: "",
            description: "",
            location: "",
          };
          this.editMode = false;
        }
        this.formModal = true;
      },
      sort(args) {
        this.page = 1;
        this.sortBy = args.sortBy;
        this.sortDir = args.sortDir;
        this.getSalesPoints();
      },

      async getAllSalesPoints() {
        if (typeof this.selectCancelToken != typeof undefined) {
          this.selectCancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.selectCancelToken = this.$http.CancelToken.source();

        let data = {
          per_page: 99999,
          page: 1,
        };

        const requestData = {
          method: "get",
          url: "salespoints",
          params: data,
          cancelToken: this.selectCancelToken.token,
        };
        this.$http(requestData)
          .then((response) => {
            let data = response.data.data;
            this.allSalesPoints = data.data;
            this.getEventAllSalesPoints();
          })
          .catch((err) => {
            console.log(err);
          });
      },
      async getEventAllSalesPoints() {
        let data = {
          per_page: 99999,
          page: 1,
        };

        const requestData = {
          method: "get",
          url: `events/${this.event.id}/salespoints`,
          params: data,
        };
        this.$http(requestData)
          .then((response) => {
            let data = response.data.data;
            this.selectedSalesPoints = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      async getSalesPoints() {
        if (typeof this.cancelToken != typeof undefined) {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.cancelToken = this.$http.CancelToken.source();

        let data = {
          search: this.searchInput,
          per_page: this.perPage,
          page: this.page,
          sort_by: this.sortBy,
          sort_dir: this.sortDir,
        };

        const requestData = {
          method: "get",
          url: `events/${this.event.id}/salespoints`,
          params: data,
          cancelToken: this.cancelToken.token,
        };
        this.$http(requestData)
          .then((response) => {
            let data = response.data.data;
            this.salesPoints = data.data;

            this.paginationData = {
              current_page: data.meta.current_page,
              from: data.meta.from,
              to: data.meta.to,
              total: data.meta.total,
              per_page: data.meta.per_page,
              last_page: data.meta.last_page,
            };
          })
          .catch((err) => {
            console.log(err);
          });
      },
      showConfirmModal(salesPoint) {
        this.$bvModal
          .msgBoxConfirm(
            this.$t("Please confirm that you want to delete salespoint") + `: ${salesPoint.identifier}.`,
            {
              title: this.$t("Please Confirm"),
              size: "sm",
              okVariant: "primary",
              okTitle: this.$t("Yes"),
              cancelTitle: this.$t("No"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then(async (confirmed) => {
            if (confirmed) {
              let salesPointIndex = 0;
              this.salesPoints = await this.salesPoints.filter(
                (salesPointItem, index) => {
                  if (salesPointItem.id == salesPoint.id) {
                    salesPointIndex = index;
                  }
                  return salesPointItem.id !== salesPoint.id;
                }
              );

              const requestData = {
                method: "delete",
                url: `events/${this.event.id}/salespoints/${salesPoint.id}`,
              };
              this.$http(requestData)
                .then((response) => {
                  this.$emit("showToast", {
                    variant: "success",
                    message: this.$t(`Salespoint deleted successfully`),
                  });
                  this.getAllSalesPoints();
                })
                .catch((err) => {
                  let message = this.$t("Unable to delete!");
                  if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                  )
                    message = err.response.data.message;
                  this.$emit("showToast", {
                    variant: "danger",
                    message,
                  });

                  this.salesPoints.splice(salesPointIndex, 0, salesPoint);
                });
            }
          });
      },
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


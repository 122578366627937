const USER_TOKEN_KEY = "userToken";
const USER_DATA_KEY = "userData";
const language_key = "language";

const StorageService = {
  getToken() {
    let token = localStorage.getItem(USER_TOKEN_KEY);
    if (!token || token.length < 10) {
      this.setToken("");
      this.setUserData("");
      return false;
    }
    return token;
  },
  setToken(token) {
    localStorage.setItem(USER_TOKEN_KEY, token);
  },
  getUserTokenKey() {
    return USER_TOKEN_KEY;
  },

  getUserData() {
    let userData = localStorage.getItem(USER_DATA_KEY);
    try {
      userData = userData ? JSON.parse(userData) : null;
    } catch (err) {
      userData = null;
      this.setUserData("");
    }

    return userData;
  },
  setUserData(userData) {
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
  },
  getLanguage() {
    let language = localStorage.getItem(language_key);
    if (!language || language.length != 2) {
      this.setLanguage("en");
      return "en";
    }
    return language;
  },
  setLanguage(val) {
    localStorage.setItem(language_key, val);
  },
};

export default StorageService;

<template>
  <div>
    <div >
      <div class="d-flex justify-content-between align-items-center mb-1">
        <per-page
          v-model="perPage"
          class=" w-50"
        />
        <search
          class=" w-50"
          v-model="searchInput"
        />

      </div>
      <table-head
        :items="tableHeadings"
        @sort="sort"
      />
      <b-row
        class="py-1 mx-1 border-bottom"
        v-for="assignment in assignments"
        :key="assignment.id"
      >
        <b-col class="d-flex align-items-center pl-0">
          <div class="font-weight-bold">
            {{assignment.agent.user.first_name}} {{assignment.agent.user.last_name}}
          </div>
        </b-col>
        <b-col class="d-flex align-items-center">
          <div class="font-weight-bold">
            {{assignment.client_first_name}} {{assignment.client_last_name}}
          </div>
        </b-col>
        <b-col class="d-flex align-items-center">
          {{assignment.product.model}} - {{assignment.product.color}}
        </b-col>
        <b-col class="d-flex align-items-center">
          {{assignment.salespoint.name}}
        </b-col>
        <b-col class="d-flex align-items-center">
          {{assignment.created_at.substring(0,10)}}
        </b-col>
        <b-col class="d-flex align-items-center">
          <b-button
            :to="`/assignments/${assignment.id}`"
            variant="flat-secondary"
            class="font-weight-bolder"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="25"
              stroke-width="2"
            />
          </b-button>
        </b-col>
      </b-row>

      <pagination
        v-model="page"
        :paginationData="paginationData"
      />
    </div>
    <!-- <div
      class="py-5 d-flex justify-content-center align-items-center"
      v-else
    >
      <span class="fontSize1-2Rem">There is no assignment</span>
    </div> -->
  </div>

</template>


<script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    BTabs,
    BTab,
  } from "bootstrap-vue";
  import PerPage from "@/components/PerPage.vue";
  import Pagination from "@/components/Pagination.vue";
  import Search from "@/components/Search.vue";
  import TableHead from "@/components/TableHead.vue";

  export default {
    props: ["event"],
    components: {
      BRow,
      BCol,
      PerPage,

      BCard,
      BAvatar,
      BButton,
      Pagination,
      Search,
      TableHead,
    },
    data() {
      return {
        perPage: 10,
        page: 1,
        assignments: null,
        defaultAvatar: require("@/assets/images/avatars/default.png"),
        cancelToken: undefined,
        sortBy: "",
        sortDir: "",
        searchInput: "",
        paginationData: null,
        tableHeadings: [
          {
            title: "agent",
            slug: "agent",
            sortable: true,
          },
          {
            title: "client",
            slug: "client",
            sortable: true,
          },
          {
            title: "model",
            slug: "model",
            sortable: true,
          },

          {
            title: "salespoint",
            slug: "salespoint",
            sortable: true,
          },
          {
            title: "date",
            slug: "created_at",
            sortable: true,
          },
          {
            title: "",
            sortable: false,
          },
        ],
      };
    },
    mounted() {
      this.getAssignments();
    },
    watch: {
      searchInput(value) {
        this.page = 1;
        this.getAssignments();
      },
      perPage(value) {
        this.page = 1;
        this.getAssignments();
      },
      page(value) {
        this.getAssignments();
      },
    },
    methods: {
      sort(args) {
        this.page = 1;
        this.sortBy = args.sortBy;
        this.sortDir = args.sortDir;
        this.getAssignments();
      },

      async getAssignments() {
        if (typeof this.cancelToken != typeof undefined) {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.cancelToken = this.$http.CancelToken.source();

        let data = {
          search: this.searchInput,
          per_page: this.perPage,
          page: this.page,
          sort_by: this.sortBy,
          sort_dir: this.sortDir,
        };

        const requestData = {
          method: "get",
          url: `events/${this.event.id}/assignments`,
          params: data,
          cancelToken: this.cancelToken.token,
        };
        this.$http(requestData)
          .then((response) => {
            let data = response.data.data;
            this.assignments = data.data;
            this.paginationData = {
              current_page: data.meta.current_page,
              from: data.meta.from,
              to: data.meta.to,
              total: data.meta.total,
              per_page: data.meta.per_page,
              last_page: data.meta.last_page,
            };
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
</script>


<style>
</style>
<template>
  <div>
    <b-row class="content-header mb-2">

      <!-- Content Left -->
      <b-col
        class="content-header-left "
        cols="12"
        md="9"
      >
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right "
        md="3"
        cols="12"
      >
        <b-button
          @click="showFormModal(null)"
          class=" px-3 text-capitalize"
          variant="primary"
        >
          <feather-icon
            size="16"
            icon="PlusIcon"
            class="mr-50"
          />
          {{$t("add company")}}
        </b-button>
      </b-col>
    </b-row>

    <div v-if="companies">
      <div class="d-flex justify-content-between align-items-center">
        <per-page
          v-model="perPage"
          class=" w-50"
        />

        <search
          ref="searchComponent"
          class=" w-50"
          v-model="searchInput"
        />
      </div>

      <b-card
        body-class="p-0"
        class=" mt-1"
      >
        <table-head
          :items="tableHeadings"
          @sort="sort"
          ref="tableHeadComponent"
        />
        <b-row
          class="py-1 mx-1 border-bottom"
          v-for="company in companies"
          :key="company.id"
        >
          <b-col class="d-flex align-items-center pl-0">
            <div class="mr-1">
              <img
                v-if="company.logoUrl"
                height="30"
                :src="company.logoUrl "
                alt=""
                srcset=""
              >
              <b-avatar
                v-else
                :text="company.name.substring(0,2)"
                size="50"
              ></b-avatar>

            </div>
            <div class="d-flex flex-column ">
              <b>
                {{company.name}}
              </b>

            </div>

          </b-col>
          <b-col class="d-flex align-items-center">
            {{company.description.substring(0,30)}}
          </b-col>

          <b-col class="d-flex align-items-center">
            <b-button
              v-if="company.deletable"
              @click="showConfirmModal(company)"
              variant="flat-danger"
              class="font-weight-bolder"
            >
              <feather-icon
                icon="Trash2Icon"
                size="20"
              />
            </b-button>
            <b-button
              @click="showFormModal(company)"
              variant="flat-secondary"
              class="font-weight-bolder"
            >
              <feather-icon
                icon="Edit2Icon"
                size="20"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <pagination
        ref="paginationComponent"
        v-model="page"
        :paginationData="paginationData"
      />
    </div>

    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon
                icon="XIcon"
                size="20"
              />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
             <h3 class="text-capitalize">{{ editMode ? $t('edit company') : $t('add company')}}</h3>

          </div>
        </div>
      </template>
      <b-container class="">

        <b-form-group class="mt-3">
          <label for="company_name" class="text-capitalize">{{("name")}}:</label>
          <b-form-input
            id="company_name"
            v-model="companyInput.name"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-3">
          <label for="company_desc" class="text-capitalize">{{$t("description")}}</label>
          <b-form-textarea
            id="company_desc"
            v-model="companyInput.description"
            rows="3"
          />
        </b-form-group>
        <div class="pl-0 ml-0 d-flex flex-column justify-content-between mt-3">
          <span class="text-capitalize">{{$t("logo")}}:</span>
          <file-picker
            class=""
            ref="logoPicker"
            :url="companyInput.logoUrl"
            v-model="logoFile"
          />

        </div>
      </b-container>
      <template #footer>

        <div class="d-flex align-items-center justify-content-end px-2 py-1">

          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
             {{$t("cancel")}}
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm()"
          >
            <div
              v-if="submitLoading"
              class="px-1"
            >
              <b-spinner
                small
                style="width: 1.3rem; height: 1.3rem;"
              />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{$t("submit")}}
            </span>

          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>

</template>

<script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    BModal,
    BFormTextarea,
    BSpinner,
    BSidebar,
    BContainer,
  } from "bootstrap-vue";
  import PerPage from "@/components/PerPage.vue";
  import Breadcrumb from "@/components/Breadcrumb.vue";
  import Pagination from "@/components/Pagination.vue";
  import Search from "@/components/Search.vue";
  import TableHead from "@/components/TableHead.vue";
  import FilePicker from "@/components/FilePicker.vue";
  export default {
    components: {
      BRow,
      BCol,
      PerPage,
      BFormGroup,
      BFormInput,
      BCard,
      BAvatar,
      BButton,
      Pagination,
      BModal,
      BFormTextarea,
      BSpinner,
      Breadcrumb,
      Search,
      TableHead,
      BSidebar,
      BContainer,
      FilePicker,
    },
    data() {
      return {
        perPage: 10,
        page: 1,

        companies: null,
        paginationData: null,
        defaultAvatar: require("@/assets/images/avatars/default.png"),
        cancelToken: undefined,
        searchInput: "",
        sortBy: "",
        sortDir: "",
        formModal: false,
        companyInput: {
          name: "",
          description: "",
          logo: "",
          logo_file: "",
        },
        submitLoading: false,
        showFileInput: false,
        logoFile: null,
        editMode: false,
        tableHeadings: [
          {
            title: "name",
            slug: "name",
            sortable: true,
          },
          {
            title: "description",
            slug: "description",
            sortable: true,
          },
          {
            title: "actions",
            sortable: false,
          },
        ],
      };
    },
    mounted() {
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
      this.getCompanies();
    },
    computed: {},
    watch: {
      searchInput(value) {
        this.page = 1;
        this.getCompanies();
      },
      perPage(value) {
        this.page = 1;
        this.getCompanies();
      },
      page(value) {
        this.getCompanies();
      },
    },
    methods: {
      sort(args) {
        this.page = 1;
        this.sortBy = args.sortBy;
        this.sortDir = args.sortDir;
        this.getCompanies();
      },
      async getCompanies() {
        if (typeof this.cancelToken != typeof undefined) {
          this.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.cancelToken = this.$http.CancelToken.source();

        let data = {
          search: this.searchInput,
          per_page: this.perPage,
          page: this.page,
          sort_by: this.sortBy,
          sort_dir: this.sortDir,
        };

        const requestData = {
          method: "get",
          url: "companies",
          params: data,
          cancelToken: this.cancelToken.token,
        };
        this.$http(requestData)
          .then((response) => {
            let data = response.data.data;
            this.companies = data.data;
            this.paginationData = {
              current_page: data.meta.current_page,
              from: data.meta.from,
              to: data.meta.to,
              total: data.meta.total,
              per_page: data.meta.per_page,
              last_page: data.meta.last_page,
            };
          })
          .catch((err) => {
            console.log(err);
          });
      },
      showConfirmModal(company) {
        this.$bvModal
          .msgBoxConfirm(
            this.$t("Please confirm that you want to delete company") +`: ${company.name}.`,
            {
              title: this.$t("Please Confirm"),
              size: "sm",
              okVariant: "primary",
              okTitle: this.$t("Yes"),
              cancelTitle: this.$t("No"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then(async (confirmed) => {
            if (confirmed) {
              let companyIndex = 0;
              this.companies = await this.companies.filter(
                (companyItem, index) => {
                  if (companyItem.id == company.id) {
                    companyIndex = index;
                  }
                  return companyItem.id !== company.id;
                }
              );

              const requestData = {
                method: "delete",
                url: `companies/${company.id}`,
              };
              this.$http(requestData)
                .then((response) => {
                  this.$bvToast.toast(
                    this.$t("Company deleted successfully"),
                    {
                      title: this.$t("Done"),
                      variant: "success",
                      solid: true,
                    }
                  );
                })
                .catch((err) => {
                  let message = this.$t("Unable to delete!");
                  if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                  )
                    message = err.response.data.message;
                  this.$bvToast.toast(message, {
                    title: this.$t("Error"),
                    variant: "danger",
                    solid: true,
                  });
                  this.companies.splice(companyIndex, 0, company);
                });
            }
          });
      },
      showFormModal(company) {
        if (company) {
          this.companyInput = { ...company };
          this.editMode = true;
        } else {
          this.companyInput = {
            name: "",
            description: "",
            logo: "",
            logoUrl: "",
          };
          this.editMode = false;
        }
        this.logoFile = null;
        this.$refs.logoPicker.clearFileInput();
        this.formModal = true;
      },

      submitForm() {
        if (this.submitLoading) return;
        let errorMessage = null;
        if (!errorMessage && !this.companyInput.name) {
          errorMessage = this.$t("Please enter company name");
        }
        if (!errorMessage && !this.companyInput.description) {
          errorMessage = this.$t("Please enter company description");
        }
        if (!errorMessage && !this.companyInput.logoUrl && !this.logoFile) {
          errorMessage = this.$t("Please select a logo");
        }

        if (errorMessage) {
          this.$bvToast.toast(errorMessage, {
            title: this.$t("Error"),
            variant: "danger",
            solid: true,
          });
          return;
        }

        this.submitLoading = true;
        let formData = new FormData();
        if (this.logoFile) formData.append("logoFile", this.logoFile);

        for (const [key, value] of Object.entries({
          ...this.companyInput,
        })) {
          formData.append(key, value);
        }
        const requestData = {
          method: "post",
          url: this.editMode
            ? `companies/${this.companyInput.id}/update`
            : "companies",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        this.$http(requestData)
          .then((response) => {
            let message = this.$t("Company added successfully");
            if (this.editMode) message = this.$t("Company updated successfully");
            this.$bvToast.toast(message, {
              title: this.$t("Done"),
              variant: "success",
              solid: true,
            });
            if (!this.editMode && this.companies) {
              this.$refs.paginationComponent.clear();
              this.$refs.searchComponent.clear();
              this.$refs.tableHeadComponent.clear();
              this.page = 1;
              this.searchInput = "";
              this.sortBy = "";
              this.sortDir = "";
            }

            this.submitLoading = false;
            this.formModal = false;
            this.getCompanies();
          })
          .catch((err) => {
            let message = this.$t("Unable to add company");
            if (this.editMode) message = this.$t("Unable to update company");
            if (err.response && err.response.data && err.response.data.message)
              message = err.response.data.message;
            this.$bvToast.toast(message, {
              title: this.$t("Error"),
              variant: "danger",
              solid: true,
            });
            this.submitLoading = false;
            console.log(err);
          });
      },
    },
  };
</script>


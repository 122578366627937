<template>
  <div>
    <div>
      <div class="d-flex justify-content-between align-items-center mb-1">
        <per-page v-model="perPage" class="w-50" />
        <search class="w-50" v-model="searchInput" />
      </div>

      <table-head
        ref="agentsTableHeadComponent"
        :items="tableHeadings"
        @sort="sort"
      />
      <b-row
        class="py-1 mx-1 border-bottom"
        v-for="agent in agents"
        :key="agent.id"
      >
        <b-col class="d-flex align-items-center pl-0">
          {{ agent.identifier }}
        </b-col>
        <b-col class="d-flex align-items-center">
          {{ agent.user.first_name }}
        </b-col>
        <b-col class="d-flex align-items-center">
          {{ agent.user.last_name }}
        </b-col>

        <b-col class="d-flex align-items-center">
          {{ agent.user.email }}
        </b-col>
        <b-col class="d-flex align-items-center">
          <invite-btn :agent="agent" :event="event" />
          <copy-invitation :agent="agent" :event="event" />
        </b-col>
      </b-row>
      <pagination
        class="mt-2"
        ref="agentsPaginationComponent"
        v-model="page"
        :paginationData="paginationData"
      />
      <b-row class="mt-3">
        <b-col class="text-right" cols="12">
          <b-button @click="sendInvitation()" class="px-2" variant="primary">
            <div v-if="invitationLoading" class="px-5">
              <b-spinner
                class="mx-2"
                small
                style="width: 1.3rem; height: 1.3rem"
              />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else>
              {{ $t("Send invitation to all agents") }}
            </span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import InviteBtn from "@/components/events/InviteBtn.vue";
import CopyInvitation from "@/components/events/CopyInvitation.vue";

export default {
  props: ["event"],
  components: {
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    InviteBtn,
    CopyInvitation,
    CopyInvitation,
  },
  data() {
    return {
      perPage: 10,
      page: 1,
      agents: [],

      cancelToken: undefined,
      selectCancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      tableHeadings: [
        {
          title: "identifier",
          slug: "identifier",
          sortable: true,
        },
        {
          title: "name",
          slug: "first_name",
          sortable: true,
        },
        {
          title: "last name",
          slug: "last_name",
          sortable: true,
        },
        {
          title: "email",
          slug: "email",
          sortable: true,
        },
        //   {
        //     title: "company",
        //     slug: "company_name",
        //     sortable: true,
        //   },

        {
          title: "actions",
          sortable: false,
        },
      ],

      invitationLoading: false,
    };
  },
  mounted() {
    this.getAgents();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getAgents();
    },
    perPage(value) {
      this.page = 1;
      this.getAgents();
    },
    page(value) {
      this.getAgents();
    },
    selectedAgents(value) {
      this.syncAgents();
    },
  },
  methods: {
    sendInvitation() {
      if (this.invitationLoading) return;

      this.invitationLoading = true;

      const requestData = {
        method: "post",
        url: `events/${this.event.id}/send-invitation-to-all`,
      };

      this.$http(requestData)
        .then((response) => {
          this.invitationLoading = false;
          this.$bvToast.toast(this.$t("Invitations sent successfully"), {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          let message = this.$t(`Unable to send invitations!`);
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;
          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
            solid: true,
          });
          this.invitationLoading = false;
          console.log(err);
        });
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getAgents();
    },
    async getAgents() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        search: this.searchInput,
        per_page: this.perPage,
        page: this.page,
        sort_by: this.sortBy,
        sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: `events/${this.event.id}/agents`,
        params: data,
        cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data.data;
          this.agents = data.data;
          // this.selectedAgents = data.data;
          this.paginationData = {
            current_page: data.meta.current_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
            per_page: data.meta.per_page,
            last_page: data.meta.last_page,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss"></style>

<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <locale />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
      v-if="userData"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{userData.first_name}}  {{userData.last_name}}
            </p>
            <span class="user-status">{{userData.level}} </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            
            :src="require('@/assets/images/avatars/default.png')"
           
          />
        </template>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider /> -->

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span class="text-capitalize">{{$t('logout')}}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
  import {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  } from "bootstrap-vue";
  import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
  import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
  import StorageService from "@/services/storage";

  export default {
    components: {
      Locale,
      BLink,
      BNavbarNav,
      BNavItemDropdown,
      BDropdownItem,
      BDropdownDivider,
      BAvatar,

      // Navbar Components
      DarkToggler,
    },
    props: {
      toggleVerticalMenuActive: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        userData: null,
      };
    },
    mounted() {
      this.userData = StorageService.getUserData();
      if (!this.userData) {
        StorageService.setToken("");
        StorageService.setUserData("");
        return this.$router.push({ path: "/login" }).catch(() => {});
      }
    },
    methods: {
      logout() {
        this.$bvModal
          .msgBoxConfirm(this.$t(`Do you want to logout?`), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then(async (confirmed) => {
            if (confirmed) {
              StorageService.setToken("");
              return this.$router.push({ path: "/login" }).catch(() => {});
            }
          });
      },
    },
  };
</script>

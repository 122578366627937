<template>
  <div>
    <b-row class="content-header mb-2">

      <!-- Content Left -->
      <b-col
        class="content-header-left "
        cols="12"
        md="9"
      >
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right "
        md="3"
        cols="12"
      >

      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="2"
        class="pr-1 pr-lg-0"
      >
        <b-card>
          <b-list-group class="list-group-filters">
            <b-list-group-item
              @click="step = 'general'"
              class="border-0 createEventStep d-flex justify-content-between align-items-center"
              :active="isActive('general')"
            >
              <div class="">
                <feather-icon
                  icon="ListIcon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1 text-capitalize">{{$t("general details")}}</span>
              </div>

              <b-badge
                v-show="isCompleted('general')"
                class=" p-25 rounded-circle"
                variant="success"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="10"
                />
              </b-badge>
            </b-list-group-item>
            <b-list-group-item
              disabled
              class="border-0 pl-0 fontSize0-9Rem pt-3 pb-0"
            >

              <span class="align-text-bottom line-height-1 text-uppercase">{{$t("event details")}}</span>
            </b-list-group-item>
            <b-list-group-item
              :disabled="!event"
              @click="step = 'branding'"
              class="border-0 createEventStep d-flex justify-content-between align-items-center mt-1"
              :active="isActive('branding')"
            >
              <div>
                <feather-icon
                  icon="BoldIcon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1 text-capitalize">{{$t("branding")}}</span>
              </div>
              <b-badge
                v-show="isCompleted('branding')"
                class=" p-25 rounded-circle"
                variant="success"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="10"
                />
              </b-badge>
            </b-list-group-item>

            <b-list-group-item
              :disabled="!event"
              @click="step = 'products'"
              class="border-0 createEventStep d-flex justify-content-between align-items-center mt-1"
              :active="isActive('products')"
            >
              <div>
                <feather-icon
                  icon="GridIcon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1 text-capitalize">{{$t("product models")}}</span>
              </div>
              <b-badge
                v-show="isCompleted('products')"
                class=" p-25 rounded-circle"
                variant="success"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="10"
                />
              </b-badge>
            </b-list-group-item>

            <b-list-group-item
              :disabled="!event"
              @click="step = 'configuration'"
              class="border-0 createEventStep d-flex justify-content-between align-items-center mt-1"
              :active="isActive('configuration')"
            >
              <div>
                <feather-icon
                  icon="SettingsIcon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1 text-capitalize">{{$t("configuration")}}</span>
              </div>
              <b-badge
                v-show="isCompleted('configuration')"
                class=" p-25 rounded-circle"
                variant="success"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="10"
                />
              </b-badge>
            </b-list-group-item>

            <b-list-group-item
              :disabled="!event"
              @click="step = 'sales-point'"
              class="border-0 createEventStep d-flex justify-content-between align-items-center mt-1"
              :active="isActive('sales-point')"
            >
              <div>
                <feather-icon
                  icon="MapPinIcon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1 text-capitalize">{{$t("salespoint")}}</span>
              </div>
              <b-badge
                v-show="isCompleted('sales-point')"
                class=" p-25 rounded-circle"
                variant="success"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="10"
                />
              </b-badge>
            </b-list-group-item>

            <b-list-group-item
              :disabled="!event"
              @click="step = 'questions'"
              class="border-0 createEventStep d-flex justify-content-between align-items-center mt-1"
              :active="isActive('questions')"
            >
              <div>
                <feather-icon
                  icon="HelpCircleIcon"
                  size="18"
                  class="mr-75"
                />
               <span class="align-text-bottom line-height-1 text-capitalize">{{$t("questions")}}</span>
              </div>
              <b-badge
                v-show="isCompleted('questions')"
                class=" p-25 rounded-circle"
                variant="success"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="10"
                />
              </b-badge>
            </b-list-group-item>

            <b-list-group-item
              disabled
              class="border-0 pl-0 fontSize0-9Rem pt-3 pb-0"
            >

              <span class="align-text-bottom line-height-1 text-uppercase">{{$t("invitation")}}</span>
            </b-list-group-item>

            <b-list-group-item
              :disabled="!event || !canInvite"
              @click="step = 'agents'"
              class="border-0 createEventStep d-flex justify-content-between align-items-center mt-1"
              :active="isActive('agents')"
            >
              <div>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1 text-capitalize">{{$t("agents")}}</span>
              </div>
              <b-badge
                v-show="isCompleted('agents')"
                class=" p-25 rounded-circle"
                variant="success"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="10"
                />
              </b-badge>
            </b-list-group-item>

          </b-list-group>
        </b-card>
      </b-col>
      <b-col cols="10">
        <b-card class="px-2 h-100">
          <general
            class="d-flex flex-column h-100 justify-content-between"
            @setEvent="setEvent"
            @addPassedStep="addPassedStep"
            @showToast="showToast"
            @goNext="goNext"
            :event="event"
            v-if="isActive('general')"
            step="general"
          />
          <branding
            class="d-flex flex-column h-100 justify-content-between"
            @setEvent="setEvent"
            @addPassedStep="addPassedStep"
            @showToast="showToast"
            @goBack="step='general'"
            @goNext="goNext"
            :event="event"
            v-if="isActive('branding')"
            step="branding"
          />
          <products
            class="d-flex flex-column h-100 justify-content-between"
            @setEvent="setEvent"
            @addPassedStep="addPassedStep"
            @showToast="showToast"
            @goBack="step='branding'"
            @goNext="goNext"
            :event="event"
            v-if="isActive('products')"
            step="products"
          />
          <configuration
            class="d-flex flex-column h-100 justify-content-between"
            @setEvent="setEvent"
            @addPassedStep="addPassedStep"
            @showToast="showToast"
            @goBack="step='products'"
            @goNext="goNext"
            :event="event"
            v-if="isActive('configuration')"
            step="configuration"
          />
          <sales-point
            class="d-flex flex-column h-100 justify-content-between"
            @setEvent="setEvent"
            @addPassedStep="addPassedStep"
            @showToast="showToast"
            @goBack="step='configuration'"
            @goNext="goNext"
            :event="event"
            v-if="isActive('sales-point')"
            step="sales-point"
          />
          <questions
            class="d-flex flex-column h-100 justify-content-between"
            @setEvent="setEvent"
            @addPassedStep="addPassedStep"
            @showToast="showToast"
            @goBack="step='sales-point'"
            @goNext="goNext"
            :event="event"
            v-if="isActive('questions')"
            step="questions"
          />
          <agents
            class="d-flex flex-column h-100 justify-content-between"
            @setEvent="setEvent"
            @addPassedStep="addPassedStep"
            @showToast="showToast"
            @goBack="step='questions'"
            @goNext="goNext"
            :event="event"
            v-if="isActive('agents')"
            step="agents"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BRow,
    BCol,
    BCard,
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,
  } from "bootstrap-vue";

  import Breadcrumb from "@/components/Breadcrumb.vue";
  import General from "@/components/events/forms/General.vue";
  import Branding from "@/components/events/forms/Branding.vue";
  import Products from "@/components/events/forms/Products.vue";
  import Configuration from "@/components/events/forms/Configuration.vue";
  import SalesPoint from "@/components/events/forms/SalesPoint.vue";
  import Questions from "@/components/events/forms/Questions.vue";
  import Agents from "@/components/events/forms/Agents.vue";

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      BListGroup,
      BListGroupItem,
      Breadcrumb,
      General,
      Branding,
      Products,
      Configuration,
      SalesPoint,
      Questions,
      Agents,
      BBadge,
    },
    data() {
      return {
        step: "general",
        event: null,
        passed_steps: [],
      };
    },
    watch: {},
    mounted() {
      this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
    },
    computed: {
      canInvite() {
        return this.passed_steps.length >= 6;
      },
    },

    methods: {
      isActive(step) {
        return this.step == step;
      },
      isCompleted(step) {
        return this.passed_steps.includes(step);
        // return this.step == step;
      },
      goNext() {
        if (this.step == "general") {
          // this.passed_steps.push("general");
          // this.passed_steps = [...new Set(this.passed_steps)];
          this.step = "branding";
          return;
        }
        if (this.step == "branding") {
          this.step = "products";
          // // this.passed_steps.push("branding");
          // this.passed_steps = [...new Set(this.passed_steps)];
          return;
        }
        if (this.step == "products") {
          this.step = "configuration";
          // this.passed_steps.push("products");
          // this.passed_steps = [...new Set(this.passed_steps)];
          return;
        }
        if (this.step == "configuration") {
          // this.passed_steps.push("configuration");
          // this.passed_steps = [...new Set(this.passed_steps)];
          this.step = "sales-point";
          return;
        }
        if (this.step == "sales-point") {
          // this.passed_steps.push("sales-point");
          // this.passed_steps = [...new Set(this.passed_steps)];
          this.step = "questions";
          return;
        }
        if (this.step == "questions") {
          // this.passed_steps.push("questions");
          // this.passed_steps = [...new Set(this.passed_steps)];
          if (this.canInvite) {
            this.step = "agents";
          }

          return;
        }
        if (this.step == "agents") {
          // this.passed_steps.push("agents");
          // this.passed_steps = [...new Set(this.passed_steps)];

          return;
        }
      },
      setEvent(event) {
        this.event = event;
      },
      addPassedStep(step) {
        this.passed_steps.push(step);
        this.passed_steps = [...new Set(this.passed_steps)];
      },
      showToast({ variant, message }) {
        this.$bvToast.toast(message, {
          title: variant == 'danger' ? this.$t('Error'): this.$t(`Done`),
          variant,
          solid: true,
        });
      },
    },
  };
</script>

<style lang="scss">
  .createEventStep {
    &.active{
       cursor: pointer;
      background-color: transparent !important;
      color: #7367f0 !important;
      border-left: 3px solid !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:hover {
      cursor: pointer;
      background-color: transparent !important;
      color: #7367f0 !important;
      border: unset;
    }
  }
</style>
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import axiosInstance from './libs/axios'
import router from './router'
import store from './store'
import App from './App.vue'
import i18n from '@/libs/i18n'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'


// BSV Plugin Registration
Vue.use(ToastPlugin, {
  BToast: {
    bodyClass: "fontSize1Rem font-weight-bold whiteSpacePreLine",
    headerClass: "fontSize1-1Rem",
    solid: true,
  }
})
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.prototype.$http = axiosInstance

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

<template>
  <div class="h-100 d-flex flex-column justify-content-between">
    <div class="">
      <p class="fontSize1-2Rem font-weight-bold">
        {{ $t("Scan Client License and Product Identification") }}
      </p>
      <div class="d-flex align-items-center mt-4">
        
        <b-form-checkbox
          class="mr-3"
          v-model="eventInput.enable_identification_recognition"
        >
          <span class="fontSize1-1Rem font-weight-bold text-capitalize">{{
            $t("license scanner")
          }}</span>
        </b-form-checkbox>
        <b-form-checkbox
          v-model="eventInput.enable_plate_recognition"
         
        >
          <span class="fontSize1-1Rem font-weight-bold text-capitalize">{{
            $t("identification scanner")
          }}</span>
        </b-form-checkbox>
      </div>
    </div>

    <b-row class="mt-5">
      <b-col class="text-right" cols="12">
        <b-button
          class="text-capitalize"
          @click="$emit('goBack')"
          variant="outline-primary"
        >
          {{ $t("back") }}
        </b-button>
        <b-button @click="submitForm()" class="mx-1 px-3" variant="primary">
          <div v-if="submitLoading" class="px-1">
            <b-spinner small style="width: 1.3rem; height: 1.3rem" />
            <span class="sr-only">Loading...</span>
          </div>
          <span class="text-capitalize" v-else>
            {{ $t("save & next") }}
          </span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BSpinner, BFormCheckbox } from "bootstrap-vue";

export default {
  props: ["event", "step"],
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormCheckbox,
  },
  data() {
    return {
      selectedConfigs: [],
      submitLoading: false,
      eventInput: {
        enable_plate_recognition: false,
        enable_identification_recognition: false,
        step: "",
      },
    };
  },
  mounted() {
    if (this.event) {
      this.eventInput = { ...this.event };

      if (this.event.enable_plate_recognition) {
        this.eventInput.enable_plate_recognition = true;
      } else {
        this.eventInput.enable_plate_recognition = false;
      }
      if (this.event.enable_identification_recognition) {
        this.eventInput.enable_identification_recognition = true;
      } else {
        this.eventInput.enable_identification_recognition = false;
      }
    }
    this.eventInput.step = this.step;
  },
  methods: {
    submitForm() {
      if (this.submitLoading) return;

      this.submitLoading = true;

      const requestData = {
        method: "post",
        url: `events/${this.event.id}/updateConfigurations`,
        data: { ...this.eventInput },
      };

      this.$http(requestData)
        .then((response) => {
          let message = this.$t("Event configuration updated successfully.");
          this.$emit("showToast", { variant: "success", message });

          this.submitLoading = false;
          this.$emit("setEvent", { ...this.eventInput });
          this.$emit("addPassedStep", this.step);
          // this.$emit("setEvent", response.data);
          this.$emit("goNext");
        })
        .catch((err) => {
          let message = this.$t("Unable to update event configuration.");
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;
          this.$emit("showToast", { variant: "danger", message });

          this.submitLoading = false;
        });
    },
  },
};
</script>

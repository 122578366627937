<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
      </b-col>
    </b-row>
    <b-tabs class="p-2" v-if="assignment">
      <b-tab active :title="$t('Details')">
        <div>
          <b-card v-if="assignment" body-class="p-0 px-2" class="mt-1">
            <div class="mt-3 border-bottom">
              <h2 class="font-weight-bolder text-capitalize">
                {{ $t("agent") }}
              </h2>
              <b-row class="fontSize1-1Rem mt-3">
                <b-col md="4">
                  <div class="mb-3">
                    <div class="font-weight-bolder mb-1 text-capitalize">
                      {{ $t("first name") }}
                    </div>
                    <div>
                      {{ assignment.agent.user.first_name }}
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="font-weight-bolder mb-1 text-capitalize">
                      {{ $t("last name") }}
                    </div>
                    <div>
                      {{ assignment.agent.user.last_name }}
                    </div>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="mb-3">
                    <div class="font-weight-bolder mb-1 text-capitalize">
                      {{ $t("identifier") }}
                    </div>
                    <div>
                      {{ assignment.agent.identifier }}
                    </div>
                  </div>

                  <div class="mb-3">
                    <div class="font-weight-bolder text-capitalize mb-1">
                      {{ $t("company") }}
                    </div>
                    <div>
                      {{ assignment.agent.company.name }}
                    </div>
                  </div>
                </b-col>

                <b-col md="4">
                  <div class="mb-3">
                    <div class="font-weight-bolder text-capitalize mb-1">
                      {{ $t("email") }}
                    </div>
                    <div>
                      {{ assignment.agent.user.email }}
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="mt-3 border-bottom">
              <h2 class="font-weight-bolder text-capitalize">
                {{ $t("client") }}
              </h2>
              <b-row class="fontSize1-1Rem mt-3">
                <b-col md="4">
                  <div class="mb-3">
                    <div class="font-weight-bolder text-capitalize mb-1">
                      {{ $t("first name") }}
                    </div>
                    <div>
                      {{ assignment.client_first_name }}
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="font-weight-bolder text-capitalize mb-1">
                      {{ $t("last name") }}
                    </div>
                    <div>
                      {{ assignment.client_last_name }}
                    </div>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="mb-3">
                    <div class="font-weight-bolder text-capitalize mb-1">
                      {{ $t("identifier") }}
                    </div>
                    <div>
                      {{ assignment.client_identifier }}
                    </div>
                  </div>

                  <div class="mb-3">
                    <div class="font-weight-bolder text-capitalize mb-1">
                      {{ $t("date of birth") }}
                    </div>
                    <div>
                      {{ assignment.client_date_of_birth }}
                    </div>
                  </div>
                </b-col>

                <b-col md="4">
                  <div class="mb-3">
                    <div class="font-weight-bolder text-capitalize mb-1">
                      {{ $t("email") }}
                    </div>
                    <div>
                      {{ assignment.client_email }}
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="font-weight-bolder text-capitalize mb-1">
                      {{ $t("salespoint") }}
                    </div>
                    <div>
                      {{ assignment.salespoint.name }}
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="mt-3 border-bottom">
              <h2 class="font-weight-bolder text-capitalize">
                {{ $t("product model") }}
              </h2>
              <b-row class="fontSize1-1Rem mt-3">
                <b-col md="4">
                  <div class="mb-3">
                    <div class="font-weight-bolder text-capitalize mb-1">
                      {{ $t("model") }}
                    </div>
                    <div>
                      {{ assignment.product.model }}
                    </div>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="mb-3">
                    <div class="font-weight-bolder text-capitalize mb-1">
                      {{ $t("color") }}
                    </div>
                    <div>
                      {{ assignment.product.color }}
                    </div>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="mb-3">
                    <div class="font-weight-bolder text-capitalize mb-1">
                      {{ $t("identifier") }}
                    </div>
                    <div>
                      {{ assignment.product.identifier }}
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </div>
      </b-tab>

      <b-tab :title="$t('Comments') + ` (${assignment.commentCount})`">
        <div class="">
          <b-card
            v-for="comment in assignment.comments"
            :key="'comment' + comment.id"
            class="mb-0 mt-2"
          >
            <div>
              {{ comment.content }}
            </div>
            <audio
              @onplay="stopAllPlayers"
              controls
              v-if="comment.fileUrl"
              class="mt-2 w-100"
            >
              <source :src="comment.fileUrl" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </b-card>
        </div>
      </b-tab>
      <b-tab :title="$t('Rates') + ` (${assignment.rate})`">
        <div class="">
          <b-card
            v-for="rate in assignment.rates"
            :key="'rate' + rate.id"
            class="mb-0 mt-2"
          >
            <div>
              {{ rate.question.content }}
            </div>
            <div class="font-weight-bold text-capitalize fontSize1-2Rem mt-1"
              >{{ $t("rate") }}: {{ rate.value }}</div
            >
          </b-card>
        </div>
      </b-tab>
    </b-tabs>
    <b-row class="my-3 px-2">
      <b-col class="d-flex justify-content-end text-right " cols="12">
        <b-button
          class="text-capitalize"
          :to="`/events/${assignment.event.id}/details`"
          variant="outline-primary"
        >
          {{ $t("back") }}
        </b-button>
        <!-- <b-button
                  @click="showSidebar = true"
                  class="ml-1 p-0 d-flex"
                  variant="primary"
                >
                  <div
                    class="p-1 text-capitalize"
                    style="border-right: 2px solid"
                  >
                    {{ $t("show comments") }}
                  </div>

                  <div class="p-1">
                    {{ assignment.comments.length }}
                  </div>
                </b-button> -->
      </b-col>
    </b-row>
    <!-- <b-sidebar
      v-if="assignment"
      width="500px"
      v-model="showSidebar"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="showSidebar = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">{{ $t("comments") }}</h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-card
          v-for="comment in assignment.comments"
          :key="'comment' + comment.id"
          class="mb-0 mt-2"
        >
          <div>
            {{ comment.content }}
          </div>
          <audio
            @onplay="stopAllPlayers"
            controls
            v-if="comment.fileUrl"
            class="mt-2 w-100"
          >
            <source :src="comment.fileUrl" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </b-card>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize"
            @click="showSidebar = false"
            variant="outline-dark"
          >
            {{ $t("close") }}
          </b-button>
        </div>
      </template>
    </b-sidebar> -->
  </div>
</template>

<script>
import {
  BSidebar,
  BContainer,
  BRow,
  BCol,
  BCard,
  BButton,
  BTabs,
  BTab,
} from "bootstrap-vue";

import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    Breadcrumb,
    BSidebar,
    BContainer,
    BTabs,
    BTab,
  },
  data() {
    return {
      assignment: null,
      showSidebar: false,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getAssignment();
  },
  watch: {
    showSidebar(value) {
      if (!value) {
        var sounds = document.getElementsByTagName("audio");
        for (let i = 0; i < sounds.length; i++) sounds[i].pause();
      }
    },
  },
  methods: {
    stopAllPlayers() {},
    async getAssignment() {
      const requestData = {
        method: "get",
        url: `assignments/${this.$route.params.id}`,
      };
      this.$http(requestData)
        .then((response) => {
          this.assignment = response.data.data;
        })
        .catch((err) => {
          let message = this.$t("Unable to fetch assignment!");
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;
          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
            solid: true,
          });
          console.log(err);
        });
    },
  },
};
</script>

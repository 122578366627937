<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
      </b-col>
    </b-row>

    <div>
      <b-card v-if="event" body-class="p-0" class="mt-1">
        <b-row class="fontSize1-1Rem mt-3">
          <b-col
            md="3"
            class="d-flex justify-content-center align-items-center pb-3"
          >
            <b-avatar :src="event.logoUrl" size="150"></b-avatar>
          </b-col>
          <b-col md="3">
            <div class="mb-3">
              <div class="font-weight-bolder mb-1 text-capitalize">
                {{ $t("name") }}
              </div>
              <div>
                {{ event.name }}
              </div>
            </div>
            <div class="mb-3">
              <div class="font-weight-bolder mb-1 text-capitalize">
                {{ $t("company") }}
              </div>
              <div>
                {{ event.company.name }}
              </div>
            </div>
            <div class="mb-3">
              <div class="font-weight-bolder mb-1 text-capitalize">
                {{ $t("rate") }}
              </div>
              <div>
                {{ event.rate }}
              </div>
            </div>
          </b-col>
          <b-col md="3">
            <div class="mb-3">
              <div class="font-weight-bolder mb-1 text-capitalize">
                {{ $t("start date") }}
              </div>
              <div>
                {{ event.start_date.substring(0, 10) }}
              </div>
            </div>
            <div class="mb-3">
              <div class="font-weight-bolder mb-1 text-capitalize">
                {{ $t("status") }}
              </div>
              <div>
                {{ event.status }}
              </div>
            </div>
            <div class="mb-3">
              <div class="font-weight-bolder mb-1 text-capitalize">
                {{ $t("comments") }}
              </div>
              <div>
                {{ event.commentCount }}
              </div>
            </div>
          </b-col>
          <b-col md="3">
            <div class="mb-3">
              <div class="font-weight-bolder mb-1 text-capitalize">
                {{ $t("end date") }}
              </div>
              <div>
                {{ event.end_date.substring(0, 10) }}
              </div>
            </div>
            <div class="mb-3">
              <div class="font-weight-bolder mb-1 text-capitalize">
                {{ $t("location") }}
              </div>
              <div>
                {{ event.location }}
              </div>
            </div>
          </b-col>
        </b-row>

        <b-tabs class="p-2">
          <b-tab active :title="$t('Assignments')">
            <assignments :event="event" />
          </b-tab>

          <b-tab :title="$t('Agents')">
            <agents :event="event" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BAvatar, BTabs, BTab } from "bootstrap-vue";
import Assignments from "@/components/events/details/Assignments.vue";
import Agents from "@/components/events/details/Agents.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BTabs,
    BTab,
    Assignments,
    Agents,
    Breadcrumb,
  },
  data() {
    return {
      event: null,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getEvent();
  },
  watch: {},
  methods: {
    async getEvent() {
      const requestData = {
        method: "get",
        url: `events/${this.$route.params.id}`,
      };
      this.$http(requestData)
        .then((response) => {
          this.event = response.data.data;
        })
        .catch((err) => {
          let message = this.$t("Unable to fetch event!");
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;
          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
            solid: true,
          });
          console.log(err);
        });
    },
  },
};
</script>

<template>
  <div class="d-flex justify-content-between align-items-center mt-2">
    <span v-if="paginationData">
      {{$t('showing')}} {{paginationData.from }} {{$t("to")}} {{paginationData.to }} {{$t("of")}} {{paginationData.total}} {{$t("entries")}}.
    </span>
    <b-pagination
      v-if="paginationData"
      v-model="page"
      :per-page="paginationData.per_page"
      hide-goto-end-buttons
      :total-rows="paginationData.total"
    >
      <template #prev-text>
        <feather-icon
          size="18"
          icon="ChevronLeftIcon"
        />
      </template>
      <template #next-text>
        <feather-icon
          size="18"
          icon="ChevronRightIcon"
        />
      </template>
    </b-pagination>
  </div>
</template>

<script>
  import { BPagination } from "bootstrap-vue";

  export default {
    props: ["paginationData", "value"],
    components: {
      BPagination,
    },
    data() {
      return {
        page: 1,
      };
    },
    methods: {
      clear() {
        this.page = 1;
      },
     
    },
    watch: {
      page(newVal) {
        this.$emit("input", this.page);
      },
    },
  };
</script>

<style>
</style>
<template>
  <div
    class="d-flex justify-content-between"
    style="margin-top:8px;"
  >
    <div
      class=" d-flex justify-content-center w-100"
      @dragover.prevent
      @drop.prevent
    >
      <img
        style="min-height:200px;"
        v-show="previewImage"
        :src="previewImage"
        class="w-100 p-1  filePickerInput cursor-pointer"
        @click="$refs.filePicker.click()"
        alt=""
      >
      <div
      @dragenter="isDragging =true"
       @dragleave="isDragging =false"
        ref="drag"
        :class="dragDropClass"
        @drop="onFileChange($event)"
        class="py-5 mr-1 justify-content-center align-items-center filePickerInput cursor-pointer w-100"
        @click="$refs.filePicker.click()"
      >
        {{$t("Drop Image here or click to upload")}}
      </div>

    </div>
    <div
      class="d-flex flex-column "
      :class="previewImage ? 'justify-content-between':'justify-content-end'"
    >
      <b-button
        v-if="file"
        @click="deleteLogo()"
        class="mb-1 text-capitalize"
        size="sm"
        variant="flat-danger"
      >
        {{$t("delete")}}
      </b-button>
      <div v-else></div>
      <b-button
        @click="$refs.filePicker.click()"
        size="sm"
        variant="flat-primary"
         class="text-capitalize"
      >
        {{previewImage ?  $t('change') : $t('upload')}}

      </b-button>
      <input
        v-if="showFileInput"
        @input="onFileChange($event)"
        type="file"
        class="d-none"
        accept="image/png, image/jpg, image/jpeg"
        ref="filePicker"
      >
    </div>

  </div>
</template>

<script>
  import { BButton, BFormFile } from "bootstrap-vue";
  export default {
    components: {
      BButton,
      BFormFile,
    },
    props: ["value", "url"],
    data() {
      return {
        file: null,
        showFileInput: true,
        isDragging: false,
      };
    },
    computed: {
      previewImage() {
        if (this.file) {
          return URL.createObjectURL(this.file);
        }

        if (this.url) return this.url;

        return null;
      },
      dragDropClass() {
        let classes = this.previewImage ? "d-none" : "d-flex";
        if(this.isDragging){
          classes += " dargBg";
        }
        
        return classes;
      },
    },
    watch: {
      file(newVal) {
        this.$emit("input", this.file);
      },
    },
    mounted() {
      // this.$refs.drag.addEventListener("dragover", () => {
      //   this.isDragging = true; // add class on drag over
      // });
      // this.$refs.drag.addEventListener("dragleave", () => {
      //   this.isDragging = false; // remove class on drag leave
      // });
    },
    methods: {
      deleteLogo() {
        this.file = null;
        this.$emit("input", this.file);
        this.clearFileInput();
      },
      clearFileInput() {
        this.showFileInput = false;
        this.file = null;
        this.$nextTick(() => {
          this.showFileInput = true;
        });
      },
      onFileChange(e) {
        this.file = null;
        this.isDragging =false
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        this.file = files[0];
        this.$emit("input", this.file);
      },
    },
  };
</script>

<style lang="scss">
  .filePickerInput {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%23D8D6DEFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 0.357rem;
    border: none;
    color: #d8d6de;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-left: 13px;
  }

  .dargBg{
    background-color: rgba(50, 50, 50, 0.1) !important;
  }
</style>
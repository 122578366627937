<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo d-flex align-items-center">
          <img
            height="40"
            src="@/assets/images/logo/nanook-logo.svg"
            alt=""
          >

          <h2 class="brand-text mt-1 mb-0 ml-1">
            Nanook
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome to Admin Panel!
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to start using the admin panel
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>

              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="login()"
            >

              <div
                v-if="loading"
                class="px-1"
              >
                <b-spinner
                  small
                  style="width: 1.3rem; height: 1.3rem;"
                />
                <span class="sr-only">Loading...</span>
              </div>
              <span v-else>
                Sign in
              </span>

            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
  import StorageService from "@/services/storage";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
  } from "bootstrap-vue";
  import VuexyLogo from "@core/layouts/components/Logo.vue";
  import { required, email } from "@validations";
  import { togglePasswordVisibility } from "@core/mixins/ui/forms";

  export default {
    components: {
      // BSV
      BButton,
      BForm,
      BFormInput,
      BFormGroup,
      BCard,
      BCardTitle,
      BLink,
      VuexyLogo,
      BCardText,
      BInputGroup,
      BInputGroupAppend,
      BFormCheckbox,
      ValidationProvider,
      ValidationObserver,
      BSpinner,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        userEmail: "",
        password: "",
        status: "",
        loading: false,
        // validation rules
        required,
        email,
      };
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
      },
    },
    mounted(){
      StorageService.setLanguage("en");
      this.$i18n.locale = "en";
    },
    methods: {
      login() {
        this.$refs.loginForm.validate().then(async (success) => {
          if (success) {
            if (this.loading) return;
            this.loading = true;
            const requestData = {
              method: "POST",
              url: "login",
              data: {
                email: this.userEmail,
                password: this.password,
                remember: this.status,
              },
            };

            await this.$http(requestData)
              .then(async (response) => {
                await StorageService.setToken(response.data.data.token);
                await StorageService.setUserData(response.data.data.user);
                if (this.$route.params.nextUrl) {
                  window.location.href = this.$route.params.nextUrl;
                  return;
                  // return this.$router
                  //   .push({ path: this.$route.params.nextUrl })
                  //   .catch(() => {});
                } else {
                  window.location.href = "/";
                  return;
                  // return this.$router.push({ path: "/" }).catch(() => {});
                }
              })
              .catch((err) => {
                let message = `Unable to login!`;
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$bvToast.toast(message, {
                  title: this.$t("Error"),
                  variant: "danger",
                  solid: true,
                });
                console.log(err);
              });

            this.loading = false;
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  @import "@core/scss/vue/pages/page-auth.scss";
</style>

<template>
  <div class="d-flex align-items-center justify-content-end ">
    <span class="mr-1 text-capitalize">
      {{$t("search")}}
    </span>
    <b-form-group class="mb-0 w-25">
      <b-form-input
        v-model="searchInput"
        placeholder=""
      />
    </b-form-group>
  </div>
</template>

<script>
  import { BFormGroup, BFormInput } from "bootstrap-vue";

  export default {
    props: ["value"],
    components: {
      BFormGroup,
      BFormInput,
    },
    data() {
      return {
        searchInput: "",
      };
    },
    methods: {
      clear() {
        this.searchInput = "";
      },
    },
    watch: {
      searchInput(value) {
        this.$emit("input", this.searchInput);
      },
    },
  };
</script>

